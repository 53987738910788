<template>
    <div class="ma-0 "  >  
        <!-- //--------------------PAY BUTTON -------------------------->
        <!-- // if not assistant -->
        <v-row  class="mb-0">
            <v-col v-if="!item_order" 
                :lg="MBS.actions.SIZE(ItemOrderOptionsData)>0?'9':'12'" 
                md="12"  
                cols="12"   
                class="pa-0 ma-0" > 
                <!-- <v-card v-if="Assistant" @click="SELECT_ORDER_OPTION({...Assistant,order_type:'assistant'})" height="100%" min-height="45" max-height="55" width="100%"   -->
                <v-card v-if="Assistant" @click="SELECT_ORDER_OPTION({name:'Send Order',key:'send_order',code:'send_order'})" height="100%" min-height="45" max-height="55" width="100%"  
                    :disabled="MBS.actions.SIZE(selected_items)<1 || inputLoading" 
                    :flat="MBS.actions.SIZE(selected_items)<1" 
                    hover  
                    class="v-btn" 
                    :class="vs.smAndDown?'':''"
                    :color="MBS.actions.SIZE(selected_items)<1?'grey darken-1':'primary'" dark >
                    <v-layout class="ma-0 px-4" fill-height align-center justify-center v-if="!inputLoading">
                        <div class="font-weight-bold not-f7">Send Order</div>
                        <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(selected_items)}} items</div>
                        <v-spacer></v-spacer>
                        <div class="font-weight-bold not-f7">MK {{MBS.actions.money(total_price)}}</div> 
                    </v-layout> 
                    <v-layout v-else class="ma-0 pa-2" justify-center>
                        <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                    </v-layout>
                </v-card> 

                <v-card v-else   @click="CREATE_ORDER()"  height="100%" min-height="45" max-height="55" width="100%"  
                    :disabled="MBS.actions.SIZE(selected_items)<1 || inputLoading" 
                    :flat="MBS.actions.SIZE(selected_items)<1" 
                    hover  
                    class="v-btn" 
                    :class="vs.smAndDown?'':''"
                    :color="MBS.actions.SIZE(selected_items)<1?'grey darken-1':'primary'" dark >
                    <v-layout class="ma-0 px-4" fill-height align-center justify-center v-if="!inputLoading">
                        <div class="font-weight-bold not-f7">Pay</div>
                        <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(selected_items)}} items</div>
                        <v-spacer></v-spacer>
                        <div class="font-weight-bold not-f7">MK {{MBS.actions.money(total_price)}}</div> 
                    </v-layout> 
                    <v-layout v-else class="ma-0 pa-2" justify-center>
                        <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                    </v-layout>
                </v-card> 
                
            </v-col>
            <v-col v-else-if="item_order?.order_type == 'quotation'"  
                md="12"  
                cols="12"     class="pa-0 ma-0">
                <v-card  @click="CREATE_ORDER()"  height="100%" min-height="45" max-height="55" width="100%"  
                    :disabled="MBS.actions.SIZE(selected_items)<1 || inputLoading" 
                    :flat="MBS.actions.SIZE(selected_items)<1" 
                    hover  
                    class="v-btn" 
                    :class="vs.smAndDown?'':''"
                    :color="MBS.actions.SIZE(selected_items)<1?'grey darken-1':'primary'" dark >
                    <v-layout class="ma-0 px-4" fill-height align-center justify-center v-if="!inputLoading">
                        <div class="font-weight-bold not-f7">Pay Quotation</div>
                        <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(selected_items)}} items</div>
                        <v-spacer></v-spacer>
                        <div class="font-weight-bold not-f7">MK {{MBS.actions.money(total_price)}}</div> 
                    </v-layout> 
                    <v-layout v-else class="ma-0 pa-2" justify-center>
                        <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                    </v-layout>
                </v-card> 
            </v-col>
            <v-col v-else-if="item_order && edit_order_items"  
                lg="9"  
                md="12"  
                cols="12"     class="pa-0 ma-0">
                <v-card  @click="ON_EDIT_ORDER_OPTION({option:'edit'})"  height="100%" min-height="45" max-height="55" width="100%"  
                    :disabled="MBS.actions.SIZE(selected_items)<1 || inputLoading" 
                    :flat="MBS.actions.SIZE(selected_items)<1" 
                    hover  
                    class="v-btn" 
                    :class="vs.smAndDown?'':''"
                    :color="MBS.actions.SIZE(selected_items)<1?'grey darken-1':'primary'" dark >
                    <v-layout class="ma-0 px-4" fill-height align-center justify-center v-if="!inputLoading">
                        <div class="font-weight-bold not-f7">Save</div>
                        <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(selected_items)}} items</div>
                        <v-spacer></v-spacer>
                        <div class="font-weight-bold not-f7">MK {{MBS.actions.money(total_price)}}</div> 
                    </v-layout> 
                    <v-layout v-else class="ma-0 pa-2" justify-center>
                        <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                    </v-layout>
                </v-card> 
            </v-col>
            <v-col v-else-if="item_order"  
                lg="9"  
                md="12"  
                cols="12"     class="pa-0 ma-0">
                <v-card @click="ON_UPDATE_ORDER_OPTION({option:'update'})"  height="100%" min-height="45" max-height="55" width="100%"  
                    :disabled="MBS.actions.SIZE(selected_items)<1 || inputLoading" 
                    :flat="MBS.actions.SIZE(selected_items)<1" 
                    hover  
                    class="v-btn" 
                    :class="vs.smAndDown?'':''"
                    :color="MBS.actions.SIZE(selected_items)<1?'grey darken-1':'primary'" dark >
                    <v-layout class="ma-0 px-4" fill-height align-center justify-center v-if="!inputLoading">
                        <div class="font-weight-bold not-f7">Update</div>
                        <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(selected_items)}} items</div>
                        <v-spacer></v-spacer>
                        <div class="font-weight-bold not-f7">MK {{MBS.actions.money(total_price)}}</div> 
                    </v-layout> 
                    <v-layout v-else class="ma-0 pa-2" justify-center>
                        <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                    </v-layout>
                </v-card> 
            </v-col>

            <v-layout :class="vs.mdAndDown?'mt-1':(item_order?'':'ml-1')" v-if="MBS.actions.SIZE(ItemOrderOptionsData)>0 && item_order?.order_type != 'quotation'"> 
                <v-menu v-if="item_order && edit_order_items"
                    v-model="menu_edit_order_option" 
                    :close-on-content-click="false" 
                    offset-y  offset-overflow
                    :nudge-width="90" rounded="xl">
    
                    <template v-slot:activator="{ on }">  
                        <v-card v-on="on"  height="" width="100%" max-height="55" min-height="45"
                            :disabled="disabled || inputLoading" 
                            :flat="disabled" 
                            hover  
                            class=" "
                            :class="vs.mdAndDown?'':'ml-1'" 
                            :color="disabled?'grey darken-1':'secondary'" dark >
                            <v-layout fill-height class="ma-0" justify-center align-center v-if="!inputLoading"> 
                                <v-spacer/> 
                                    <div class="font-weight-bold  " :class="dense?'not-f3 pl-1':'not-f5'">Options</div>
                                <v-spacer/>
                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                            </v-layout> 
                            <v-layout v-else class="ma-0 pa-2" justify-center>
                                <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                            </v-layout>
                        </v-card>   
                    </template>  
                    <v-card>
                        <v-card-title primary-title class="secondary white--text">
                            Select Edit Option
                        </v-card-title>
                        <v-list class="pb-3">
                            <v-list-item  @click="ON_EDIT_ORDER_OPTION({option:'edit'})">
                                Save Changes
                            </v-list-item>
                            <v-list-item v-if="CurrentOutlet?.outlet_type_code=='restaurant'"  
                                @click="ON_EDIT_ORDER_OPTION({option:'edit_print_docket'})">
                                Save & Print Docket
                            </v-list-item>
                            <v-list-item @click="ON_EDIT_ORDER_OPTION({option:'edit_bill'})">
                                Save & Print Bill
                            </v-list-item>
                        </v-list>
                        <v-divider/>
                        <v-card-actions class="pa-0 ma-0">
                            <v-btn text width="100%" color="secondary" @click="ON_EDIT_ORDER_OPTION({option:'edit_cancel'})">Cancel Editing</v-btn> 
                        </v-card-actions> 
                    </v-card>
                </v-menu>  
                <v-menu v-else-if="item_order"
                    v-model="menu_update_order_option" 
                    :close-on-content-click="false" 
                    offset-y  offset-overflow
                    :nudge-width="90" rounded="xl">
    
                    <template v-slot:activator="{ on }">   
                        <v-card v-on="on"  height="" width="100%" max-height="55" min-height="45"
                            :disabled="disabled || inputLoading" 
                            :flat="disabled" 
                            hover  
                            class=" "
                            :class="vs.mdAndDown?'':'ml-1'" 
                            :color="disabled?'grey darken-1':'secondary'" dark >
                            <v-layout fill-height class="ma-0" justify-center align-center v-if="!inputLoading"> 
                                <v-spacer/> 
                                    <div class="font-weight-bold  " :class="dense?'not-f3 pl-1':'not-f5'">Option</div>
                                <v-spacer/>
                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                            </v-layout> 
                            <v-layout v-else class="ma-0 pa-2" justify-center>
                                <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                            </v-layout>
                        </v-card>  
                    </template>  
                    <v-card>
                        <v-card-title primary-title class="secondary white--text">
                            Select Update Option
                        </v-card-title>
                        <v-list class="pb-3">
                            <v-list-item  @click="ON_UPDATE_ORDER_OPTION({option:'update'})">
                                Update Order
                            </v-list-item>
                            <v-list-item v-if="CurrentOutlet?.outlet_type_code=='restaurant'"  
                                @click="ON_UPDATE_ORDER_OPTION({option:'update_print_docket'})">
                                Update & Print Docket
                            </v-list-item>
                            <v-list-item @click="ON_UPDATE_ORDER_OPTION({option:'update_bill'})">
                                Update & Print Bill
                            </v-list-item>
                        </v-list>
                        <v-divider/>
                        <v-card-actions class="pa-0 ma-0">
                            <v-btn text width="100%" color="secondary" @click="ON_UPDATE_ORDER_OPTION({option:'update_cancel'})">Cancel Update</v-btn> 
                        </v-card-actions> 
                    </v-card>
                </v-menu> 
                <v-menu v-else
                    v-model="menu_select_order_option" 
                    :close-on-content-click="false" 
                    offset-y  offset-overflow
                    :nudge-width="20" rounded="xl">
    
                    <template v-slot:activator="{ on }">   
                        <v-card v-on="on"  height="" width="100%" max-height="55" min-height="45"
                            :disabled="disabled || inputLoading" 
                            :flat="disabled" 
                            hover  
                            class=" "
                            :class="vs.mdAndDown?'':''" 
                            :color="disabled?'grey darken-1':'secondary'" dark >
                            <v-layout fill-height class="ma-0" justify-center align-center v-if="!inputLoading"> 
                                <v-spacer/> 
                                    <div class="font-weight-bold  " :class="dense?'not-f3 pl-1':'not-f5'">Order Option</div>
                                <v-spacer/>
                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                            </v-layout> 
                            <v-layout v-else class="ma-0 pa-2" justify-center>
                                <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                            </v-layout>
                        </v-card>  
                    </template>  
                    <v-card>
                        <v-card-title primary-title class="secondary white--text">
                            Select Order Option
                        </v-card-title>
                        <v-list class="pb-3">
                            <v-list-item   @click="CREATE_QUOTATION()">
                                Create Quotation
                            </v-list-item>
                            <v-divider v-if="CurrentOutlet?.outlet_type_code=='restaurant'" />
                            <v-list-item v-if="CurrentOutlet?.outlet_type_code=='restaurant'"  @click="SELECT_ORDER_OPTION({name:'Send Order',key:'send_order',code:'send_order'})">
                                Send New Order
                            </v-list-item>
                            <v-list-item v-if="CurrentOutlet?.outlet_type_code=='restaurant'"  @click="SELECT_ORDER_OPTION({name:'Update Order',key:'update_order',code:'update_order'})">
                                Update Exiting Order
                            </v-list-item>

                            <v-divider/>
                            <v-list-item v-for="(order_option, index) in ActionAndProcessingOrderOption" :key="index" 
                                @click="SELECT_ORDER_OPTION(order_option)">
                                {{order_option.name}}
                            </v-list-item>
                            <!-- <v-divider></v-divider> -->
                            
                        </v-list>
                    </v-card>
                </v-menu>   
            </v-layout>
        </v-row>   

        <!-- //---------------------DIALOG -->
        <div>  
            <!-- CREATE QUOTATION -->
            <v-dialog
                v-model="dialog_create_quotation"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_create_quotation"> 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">CUSTOMER QUOTATION</div> 
                        <v-spacer></v-spacer>
                        <!-- <div class="font-weight-bold b1--text not-f7">mwk{{MBS.actions.money(TotalDiscountedPrice)}}</div> -->
                         <v-btn @click="dialog_create_quotation=!dialog_create_quotation" icon dark><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br>  
                        <form @submit.prevent="SUBMIT_QUOTATION(input,SelectedOrderOption)"> 
                            <mbs-input-fields class="mx-3"
                                v-model="input"
                                :outlined="true"
                                :dense="false"
                                py="1" px="1"
                                :field_items="DATA.FIELDS.sale_on_account"
                                :select_items="DataSource"  
                                />   
                            <v-layout row wrap class="ma-0 " justify-center align-center>
                                
                                <v-flex sm8>
                                    <v-btn type="submit" large   
                                        :loading="inputLoading"
                                        class="mt-3 "  color="primary" height="60" width="99%">
                                        <div class="not-f5">ISSUE QUOTATION</div>  
                                    </v-btn>   
                                </v-flex> 

                                <v-flex sm4> 
                                    <v-btn width="100%" height="60" class="mt-3" color="secondary">View</v-btn>
                                    <!-- <v-menu v-if="MBS.actions.SIZE(ItemOrderOptionsData)>0"
                                        v-model="menu_send_and_print_option" 
                                        :close-on-content-click="false" 
                                        offset-y  offset-overflow
                                        :nudge-width="90" rounded="xl">

                                        <template v-slot:activator="{ on }">   
                                             
                                            <v-btn large  v-show="false" type="submit"
                                                :loading="inputLoading" ref="ref_btn_send_and_print"
                                                class="mt-3 "  color="secondary" height="60" width="99%">
                                                <div class="not-f5">Send & Print</div>  
                                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                            </v-btn> 
                                            <v-btn large  v-on="on"
                                                :loading="inputLoading" 
                                                class="mt-3 "  color="secondary" height="60" width="99%">
                                                <div class="not-f5">Send & Print</div>  
                                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                            </v-btn> 
                                        </template>  
                                        <v-card>
                                            <v-card-title primary-title class="secondary white--text">
                                                Select Print Option
                                            </v-card-title>
                                            <v-list>
                                                <v-list-item type="submit"  @click="SEND_AND_PRINT_NEW_ORDER('print_docket_and_bill')">
                                                    Send & Print Both Docket & Bill
                                                </v-list-item> 
                                                <v-list-item type="submit" @click="SEND_AND_PRINT_NEW_ORDER('print_docket')">
                                                    Send & Print Docket
                                                </v-list-item>
                                                <v-list-item type="submit" @click="SEND_AND_PRINT_NEW_ORDER('print_bill')">
                                                    Send & Print Bill
                                                </v-list-item> 
                                            </v-list>
                                        </v-card>
                                    </v-menu>  -->
                                </v-flex> 
                            </v-layout>  
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog>  
            
            <!-- NEW ORDER OPTIONS -->
            <v-dialog
                v-model="dialog_new_order_option"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_new_order_option"> 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">ORDER OPTION</div> 
                        <v-spacer></v-spacer>
                        <!-- <div class="font-weight-bold b1--text not-f7">mwk{{MBS.actions.money(TotalDiscountedPrice)}}</div> -->
                         <v-btn @click="dialog_new_order_option=!dialog_new_order_option" icon dark><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <div class="mb-2">Order details  </div>
                        <form @submit.prevent="CREATE_NEW_ORDER(input,SelectedOrderOption)"> 
                            <mbs-input-fields class="mx-3"
                                v-model="input"
                                :outlined="true"
                                :dense="false"
                                py="1" px="1"
                                :field_items="DATA.FIELDS.send_order_options"
                                :select_items="DataSource"  
                                />  
                            <v-layout row wrap class="ma-0 " >
                                
                                <v-flex sm8>
                                    <v-btn type="submit" large  
                                        @click="PUT_AND_PRINT = false"
                                        :loading="inputLoading"
                                        class="mt-3 "  color="primary" height="60" width="99%">
                                        <div class="not-f5">{{ SelectedOrderOption?SelectedOrderOption.name:'Send New Order' }}</div>  
                                    </v-btn>   
                                </v-flex> 

                                <v-flex sm4> 
                                    <v-menu v-if="MBS.actions.SIZE(ItemOrderOptionsData)>0"
                                        v-model="menu_send_and_print_option" 
                                        :close-on-content-click="false" 
                                        offset-y  offset-overflow
                                        :nudge-width="90" rounded="xl">

                                        <template v-slot:activator="{ on }">   
                                             
                                            <v-btn large  v-show="false" type="submit"
                                                :loading="inputLoading" ref="ref_btn_send_and_print"
                                                class="mt-3 "  color="secondary" height="60" width="99%">
                                                <div class="not-f5">Send & Print</div>  
                                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                            </v-btn> 
                                            <v-btn large  v-on="on"
                                                :loading="inputLoading" 
                                                class="mt-3 "  color="secondary" height="60" width="99%">
                                                <div class="not-f5">Send & Print</div>  
                                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                            </v-btn> 
                                        </template>  
                                        <v-card>
                                            <v-card-title primary-title class="secondary white--text">
                                                Select Print Option
                                            </v-card-title>
                                            <v-list>
                                                <v-list-item type="submit"  @click="SEND_AND_PRINT_NEW_ORDER('print_docket_and_bill')">
                                                    Send & Print Both Docket & Bill
                                                </v-list-item> 
                                                <v-list-item type="submit" @click="SEND_AND_PRINT_NEW_ORDER('print_docket')">
                                                    Send & Print Docket
                                                </v-list-item>
                                                <v-list-item type="submit" @click="SEND_AND_PRINT_NEW_ORDER('print_bill')">
                                                    Send & Print Bill
                                                </v-list-item> 
                                            </v-list>
                                        </v-card>
                                    </v-menu> 
                                </v-flex> 
                            </v-layout>  
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog>  
            
            <!-- KITCHEN UPDATE ORDER OPTIONS -->
            <v-dialog
                v-model="dialog_update_order_option"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_update_order_option"> 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">UPDATE ORDER OPTION</div> 
                        <v-spacer></v-spacer>
                        <v-btn @click="dialog_update_order_option=false" dark icon><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <div class="mb-2">Order details  </div>
                        <form @submit.prevent="UPDATE_EXISTING_ORDER(input,SelectedOrderOption,ORDER_PRINT_OPTIONS)"> 
                            <mbs-input-fields class="mx-3"
                                v-model="input"
                                :outlined="true"
                                :dense="false"
                                py="1" px="1"
                                :field_items="DATA.FIELDS.update_order_options"
                                :select_items="DataSource"  
                                />  
                            <v-layout row wrap class="ma-0 " >
                                
                                <v-flex sm8>
                                    <v-btn type="submit" large  
                                        @click="PUT_AND_PRINT = false"
                                        :loading="inputLoading"
                                        class="mt-3 "  color="primary" height="60" width="99%">
                                        <div class="not-f5">{{ SelectedOrderOption?SelectedOrderOption.name:'..' }}</div>  
                                    </v-btn>   
                                </v-flex> 
                                <v-flex sm4>
                                    <v-btn type="submit"  large  :v-show="false"
                                        :loading="inputLoading" v-show="false"  ref="ref_btn_update_print"
                                        class="mt-3 "  color="secondary" height="60" width="99%">
                                        <div class="not-f5">Update & Print</div>   
                                    </v-btn>   
                                    <v-menu  
                                        v-model="menu_update_existing_order_option" 
                                        :close-on-content-click="false" 
                                        offset-y  offset-overflow
                                        :nudge-width="90" rounded="xl"> 
                                        <template v-slot:activator="{ on }">    
                                            <v-btn v-on="on"  large  
                                                :loading="inputLoading" 
                                                class="mt-3 "  color="secondary" height="60" width="99%">
                                                <div class="not-f5">Update & Print</div>  
                                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                            </v-btn>   

                                            <!-- <v-card v-on="on"  height="" width="100%" max-height="55" min-height="45"
                                                :disabled="disabled || inputLoading" 
                                                :flat="disabled" 
                                                hover  
                                                class=" "
                                                :class="vs.mdAndDown?'':''" 
                                                :color="disabled?'grey darken-1':'secondary'" dark >
                                                <v-layout fill-height class="ma-0" justify-center align-center v-if="!inputLoading"> 
                                                    <v-spacer/> 
                                                        <div class="font-weight-bold  " :class="dense?'not-f3 pl-1':'not-f5'">Update</div>
                                                    <v-spacer/>
                                                    <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                                </v-layout> 
                                                <v-layout v-else class="ma-0 pa-2" justify-center>
                                                    <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                                </v-layout>
                                            </v-card>   -->
                                        </template>  
                                        <v-card>
                                            <v-card-title primary-title class="secondary white--text">
                                                Select Update Option
                                            </v-card-title>
                                            <v-list class="pb-3">
                                                <v-list-item  @click="ON_UPDATE_EXISTING_ORDER_OPTION({option:'update'})">
                                                    Update Order
                                                </v-list-item>
                                                <v-list-item v-if="CurrentOutlet?.outlet_type_code=='restaurant'"  
                                                    @click="ON_UPDATE_EXISTING_ORDER_OPTION({option:'update_print_docket'})">
                                                    Update & Print Docket
                                                </v-list-item>
                                                <v-list-item @click="ON_UPDATE_EXISTING_ORDER_OPTION({option:'update_bill'})">
                                                    Update & Print Bill
                                                </v-list-item>
                                            </v-list>
                                            <v-divider/>
                                            <v-card-actions class="pa-0 ma-0">
                                                <v-btn text width="100%" color="secondary" @click="ON_UPDATE_ORDER_OPTION({option:'update_cancel'})">Cancel Update</v-btn> 
                                            </v-card-actions> 
                                        </v-card>
                                    </v-menu>
                                </v-flex> 
                            </v-layout>  
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog>  

            <!-- View ORDER  DIALOG -->
            <v-dialog
                v-model="dialog_view_order"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="750px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_view_order" > 
                    <!-- //----Header----- -->
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span >BILL NUMBER:</span> 
                            {{SELECTED_ORDER.key}}<span v-if="SELECTED_ORDER.mkey">({{SELECTED_ORDER.mkey}})</span>
                        </div> 
                        <v-spacer></v-spacer>  
                        <v-btn class="ml-9" @click="dialog_view_order=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>    

                    <!-- //-----Body----------------- -->
                    <v-card-text class="b1">
                        <!-- -----------receipt short info-------- -->
                        <br>
                        <v-card outlined>
                            <v-card-text> 
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Customer:</div>
                                    <div class="ml-2 font-weight-bold">{{ SELECTED_ORDER.customer_name }}</div>
                                    <v-spacer/>
                                    <span v-if="SELECTED_ORDER?.table">
                                        <span>Table:</span>
                                        <span class="ml-2 font-weight-bold">{{ SELECTED_ORDER.table_name }}</span> 
                                    </span><span v-else-if="SELECTED_ORDER?.till">
                                        <span>Till:</span>
                                        <span class="ml-2 font-weight-bold">{{ SELECTED_ORDER.till_name }}</span> 
                                    </span>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Total Amount:</div>
                                    <v-spacer/>
                                    <span class="mt-2">MWK</span><div class="ml-1 font-weight-bold not-f7">{{ SELECTED_ORDER.total_sold_price_ }}</div>
                                </v-layout>
                            </v-card-text> 
                        </v-card>

                        <!-- -------Item list--------- -->
                        <v-card outlined class="mt-2">
                            <!-- //list -->
                            <v-list>
                                <v-list-item @click="
                                        SELECTED_ORDER.expand_items=!SELECTED_ORDER.expand_items 
                                        if (SELECTED_ORDER.expand_details) {
                                            SELECTED_ORDER.expand_details = false
                                        }
                                    ">
                                    <v-list-item-content><div class="font-weight-bold ">{{MBS.actions.SIZE(SELECTED_ORDER.joined_items)}} Item(s)</div></v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn  icon>
                                            <v-icon v-if="SELECTED_ORDER.expand_items">mdi-chevron-up</v-icon>
                                            <v-icon v-else>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list> 
                            <!-- //list expand  -->
                            <div class="mx-2 mb-2" v-if="SELECTED_ORDER.expand_items"> 
                                <v-simple-table  
                                    fixed-header
                                    :min-height="vsh-590"
                                    >
                                    <template v-slot:default>
                                        <!-- header -->
                                        <thead>
                                            <tr class=" table-background ">
                                                <th class="text-left with-divide font-weight-bold">
                                                    Name
                                                </th>
                                                <th class="text-left with-divide  ">
                                                    price
                                                </th>
                                                <th class="text-left with-divide  ">
                                                    Quantity
                                                </th>
                                                <th class="text-left with-divide ">
                                                    Total Price
                                                </th>
                                            </tr>
                                        </thead>
                                        <!-- body -->
                                        <tbody>
                                            <!-- ------------listing items by department------------- -->
                                            <template  v-if="MBS.actions.SIZE(SELECTED_ORDER.joined_items_departments)"> 
                                                <template v-for="(department,index) in SELECTED_ORDER.joined_items_departments" >
                                                    <v-card class="primary lighten-3 font-weight-bold" dark :key="'_'+index"> 
                                                        <v-layout class="justify-center py-1">
                                                            {{ department.name }} 
                                                        </v-layout>
                                                    </v-card>
                                                    <tr v-for="(item,index2) in MergeItems(department.joined_items,'order') " class="b1" >
                                                        <td class="with-divider ">
                                                            {{ item.name }}
                                                            <span v-if="item.selected_item_option_names">
                                                                ({{ item.selected_item_option_names }})
                                                            </span>
                                                        </td>
                                                        <td class="with-divider">{{ MBS.actions.money(item.sold_price) }}</td>
                                                        <td class="with-divider">{{ item.quantity }}</td>
                                                        <td class="with-">{{ MBS.actions.money(item.quantity*item.sold_price) }}</td>
                                                    </tr> 
                                                </template>
                                            </template>
                                            <template v-else>
                                                <tr v-for="item in SELECTED_ORDER.joined_items"  >
                                                    <td class="with-divider ">
                                                        {{ item.name }}
                                                        <span v-if="item.selected_item_option_names">
                                                            ({{ item.selected_item_option_names}})
                                                        </span>
                                                    </td>
                                                    <td class="with-divider">{{ MBS.actions.money(item.sold_price) }}</td>
                                                    <td class="with-divider">{{ item.quantity }}</td>
                                                    <td class="with-">{{ MBS.actions.money(item.quantity*item.sold_price) }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </template>
                                </v-simple-table> 
                            </div>
                        </v-card>

                        <v-card class="pa-2 mt-2" outlined>
                            <v-list>
                                <v-list-item @click="
                                            SELECTED_ORDER.expand_details=!SELECTED_ORDER.expand_details 
                                            if (SELECTED_ORDER.expand_items) {
                                                SELECTED_ORDER.expand_items=false
                                            }
                                        ">
                                    <v-list-item-content><div class="font-weight-bold">Order Details</div></v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn  icon>
                                            <v-icon v-if="SELECTED_ORDER.expand_details">mdi-chevron-up</v-icon>
                                            <v-icon v-else>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list> 
                            <div v-if="SELECTED_ORDER.expand_details"> 
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Order Number:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.key }} <span v-if="SELECTED_ORDER.mkey">({{ SELECTED_ORDER.mkey }})</span></div>
                                </v-layout>
                                <v-layout align-center class="my-1" v-if="IsRestaurant">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Table:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.table_name }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1"  v-if="IsRestaurant">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Waiter:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.waiter_name }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-cash-register</v-icon>
                                    <div>Initiated By:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.teller_name }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-clipboard-text-clock-outline</v-icon>
                                    <div>Initiated Time:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.created_at_ }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-timer-settings-outline</v-icon>
                                    <div>Estimated Time:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.estimated_time?SELECTED_ORDER.estimated_time:"..." }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-list-status</v-icon>
                                    <div>Status:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.estimated_time }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">home</v-icon>
                                    <div>Description:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.description }}</div>
                                </v-layout> 
                            </div>
                        </v-card>
                    </v-card-text>  

                    <!-- //-----Action Buttons--------- -->
                    <v-divider></v-divider>
                    <v-card-actions >
                        <v-layout class="ma-0 pa-1" row wrap justify-center align-center> 
                            <v-btn-toggle  dark  class="mb-2" >
                                <v-btn  @click="EXIT_DIALOG()"  color="primary" value="exit">
                                    <v-icon class="">mdi-exit-to-app</v-icon> 
                                    <span class="hidden-sm-and-down px-1 ">EXIT</span>
                                </v-btn>
                                
                                <v-btn  @click="CONFIRM_DELETE_ITEM_ORDER({
                                        order:SELECTED_ORDER,
                                        deleting_reason:'user_delete',
                                        notify:true,
                                    })"  
                                    color="primary" value="delete">
                                    <v-icon class="">mdi-delete</v-icon>
                                    <span class="hidden-sm-and-down">Delete</span> 
                                </v-btn>
    
                                <v-btn @click="ON_UPDATE_BILL(SELECTED_ORDER)"  color="primary" value="edit">
                                    <v-icon class="">edit</v-icon>
                                    <span class="hidden-sm-and-down pl-1">Update</span> 
                                </v-btn>
                                
                                <!-- @click="PRINT_ORDER_OPTION_DOCKET(SELECTED_ORDER,'bill_sale_order')" -->
                                
                                <v-menu v-if="MBS.actions.SIZE(ItemOrderOptionsData)>0"
                                    v-model="menu_select_order_docket" 
                                    :close-on-content-click="false" 
                                    offset-y  offset-overflow
                                    :nudge-width="90" rounded="xl">

                                    <template v-slot:activator="{ on }">   
                                        <v-btn v-on="on"  color="primary" value="print_docket">
                                            <v-icon  class="">print</v-icon>
                                            <span class="hidden-sm-and-down px-1">Print Docket</span>
                                            <v-icon class="">arrow_drop_down</v-icon>
                                        </v-btn>
                                    </template>  
                                    <v-card>
                                        <v-card-title primary-title class="secondary white--text">
                                            Select Docket Version
                                        </v-card-title>
                                        <v-list>
                                            <v-list-item  @click="ON_REPRINT_DOCKET(SELECTED_ORDER,'docket_all_updates')">
                                                For All Updates
                                            </v-list-item> 
                                            <v-list-item  @click="ON_REPRINT_DOCKET(SELECTED_ORDER,'docket_first_update')">
                                                First Update
                                            </v-list-item>
                                            <v-list-item  @click="ON_REPRINT_DOCKET(SELECTED_ORDER,'docket_last_update')">
                                                Last Update
                                            </v-list-item> 
                                        </v-list>
                                    </v-card>
                                </v-menu> 
    
                                <v-btn @click="ON_REPRINT_BILL(SELECTED_ORDER,'bill_sale_order')"  color="primary" value="print_order">
                                    <v-icon class="">print</v-icon>
                                    <span class="hidden-sm-and-down pl-1">Print Bill</span> 
                                </v-btn>
    
                                <v-btn v-if="!Assistant"  @click="PAY_ITEM_ORDER(SELECTED_ORDER)"  color="primary" value="pay">
                                    <v-icon class="">mdi-cash</v-icon>
                                    <span class="hidden-sm-and-down pl-1">Pay</span> 
                                </v-btn> 
                            </v-btn-toggle>
                        </v-layout> 
                    </v-card-actions>
                </v-card> 
            </v-dialog>  

            <!-- View ORDERS list Option DIALOG -->
            <span v-if="dialog_view_orders_list"> 
                <v-dialog tile
                    v-model="dialog_view_orders_list"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="900px"
                    transition="dialog-transition" >
                    <v-card tile   v-if="dialog_view_orders_list" > 
                        <v-card-title class="primary pb-1 pt-2">
                            <div class="font-weight-bold b2--text"> 
                                <span >ORDER(S)</span>  
                            </div> 
                            <v-autocomplete v-if="show_device_selector" class="ml-2 mt-1" dark  
                                :label="!current_device?'Select device':''" 
                                prepend-inner-icon="mdi-cash-register" 
                                clearable 
                                filled
                                rounded :disabled="Assistant?true:false"
                                :items="CashupDevices"
                                :item-text="'name'"
                                item-value="key"
                                outlined
                                v-model="current_device"
                                ></v-autocomplete>
                            <v-spacer></v-spacer> 
                            <v-btn @click="CHANGE_ORDER_LIST_VIEW('customer')" class="mx-1" tile small color="secondary" >
                                Customer Orders
                                <v-icon class="ml-2"  >mdi-cart-heart</v-icon>
                            </v-btn>
                            <v-btn :loading="DOWNLOADING_ORDERS?true:false" tile small color="secondary" @click="REFRESH_ORDERS()">
                                <!-- <v-icon  size="18"  color="white" class="mr-1">{{ DOWNLOADED_ORDERS?'mdi-lightbulb':'mdi-lightbulb-off' }}</v-icon> -->
                                Refresh 
                                <v-icon class="ml-2" >mdi-refresh</v-icon>
                            </v-btn> 
                            <v-menu bottom left  rounded="xl" >
                                <template v-slot:activator="{ on }">
                                <v-btn class="ml-2"
                                    dark
                                    icon
                                    v-on="on" >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                                </template> 
                                <v-card>
                                    <v-card-title class="secondary white--text not-f4" >
                                        SELECT ACTION
                                    </v-card-title> 
                                    <v-list  > 
                                        <div v-for="(item, index) in TableActions" :key="index">
                                            <v-list-item  @click="TABLE_ACTION(item.action,item)" >
                                                <v-list-item-action class="mr-3" ><v-icon>{{item.icon}}</v-icon></v-list-item-action>
                                                <v-list-item-title class="">{{item.text}}</v-list-item-title>
                                            </v-list-item> 
                                        </div>
                                         
                                    </v-list> 
                                </v-card>
                            </v-menu>
                        </v-card-title>
                        <mbs-page-tab v-if="MBS.actions.SIZE(TabOderOptions)>2"
                            v-model="currentOrderOptionTab"
                            color="b2"
                            text_color="black" dark
                            :items="TabOderOptions"/> 
                        <v-card-text>
                            <br> 
                            <v-card outlined class="mbs-scroll-y"  :height="vsh-355"> 
                                <v-list class="" v-if="MBS.actions.SIZE(PageItemOrders)!=0"
                                    :bench="2" 
                                    item-height="70" > 
                                    <template v-for="(item,index) in PageItemOrders"> 
                                        <v-list-item @click="ON_VIEW_ORDER(item,index)" :key="index"> 
                                            <v-list-item-action>  
                                                <v-layout row wrap> 
                                                    <v-badge  style="height: 100%;"
                                                        class=""  
                                                        :value="item.new_order"  
                                                        :content="'new'"
                                                        :color="'green'"
                                                        :bordered="true" 
                                                        :overlap="true"  >
                                                        <v-avatar :class="item.expand?'':''"
                                                            size="40"   
                                                            rounded="lg" class="ml-2"
                                                            color="grey"  >
                                                            <v-img v-if="item.image"></v-img>
                                                            <v-icon v-else>shopping_cart</v-icon>
                                                        </v-avatar> 
                                                    </v-badge>
                                                </v-layout>
                                            </v-list-item-action>  
                                            <v-list-item-content>
                                                <v-list-item-title> 
                                                    <span v-if="item?.till">{{ item.till_name }}: </span>
                                                    <span v-if="item?.waiter">{{item.waiter_name}}</span>
                                                    <span v-else-if="item?.teller">{{item.teller_name}}</span>
                                                </v-list-item-title>
                                                <v-list-item-subtitle v-if="item?.table">Table:{{item.table_name}}</v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="item?.customer">Customer:{{item.customer_name}}</v-list-item-subtitle>
                                                <v-list-item-subtitle>Bill No:   <strong >{{item.key}}<span v-if="item.mkey">({{ item.mkey }})</span></strong> /  Items: {{item.total_quantity_}} <span class="ml-3">Date:</span>{{item.local_created_at_}}</v-list-item-subtitle>
                                                
                                            </v-list-item-content>
                                                
                                            <v-list-item-action>
                                                <v-card color="transparent" flat width="100">
                                                <v-layout class="ma-0">
                                                    <v-spacer></v-spacer>
                                                    {{item.total_sold_price_}}
                                                </v-layout>
                                                </v-card>
                                            </v-list-item-action>  
                                            <v-list-item-action >
                                                <!-- <v-btn @click="CONFIRM_REMOVE_ITEM(item,index)" 
                                                :disabled="inputLoading" small icon><v-icon>delete</v-icon></v-btn> -->
                                                <v-btn  icon> 
                                                    <v-icon >chevron_right</v-icon>
                                                </v-btn> 
                                            </v-list-item-action> 
                                        </v-list-item> 
                                        <v-divider  :key="'_'+index"/> 
                                    </template>
                                </v-list> 
                                <v-layout v-else fill-height justify-center align-center>
                                    <v-chip close v-if="search" large class="px-5" @click:close="search=''">
                                        <v-icon>warning</v-icon> 
                                        <div class="not-f5 ml-2 grey--text" >No Order match "{{ search }}"</div>
                                    </v-chip>
                                    <v-chip v-else large class="px-5">
                                        <v-icon>warning</v-icon> 
                                        <div class="not-f6 ml-2 grey--text" >No Orders</div>
                                    </v-chip>
                                </v-layout>
                            </v-card>  
                        </v-card-text>  
                    </v-card> 
                </v-dialog>  
            </span>
            
            <!-- View Assistant ORDERS DIALOG -->
            <v-dialog
                v-model="dialog_view_assistant_orders"
                scrollable   tile 
                :persistent="false" :overlay="false"
                max-width="900px"
                transition="dialog-transition" >
                <v-card tile  v-if="dialog_view_assistant_orders" >  
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span >ASSISTANT ORDER(S)</span>  
                        </div> 
                        <v-autocomplete v-if="show_device_selector" class="ml-2 mt-1" dark  
                                :label="!current_device?'Select device':''" 
                                prepend-inner-icon="mdi-cash-register" 
                                clearable 
                                filled
                                rounded :disabled="Assistant?true:false"
                                :items="CashupDevices"
                                :item-text="'name'"
                                item-value="key"
                                outlined
                                v-model="current_device"
                                ></v-autocomplete>
                        <v-spacer></v-spacer> 
                        <v-btn @click="CHANGE_ORDER_LIST_VIEW('list')" class="mx-1" tile small color="secondary" >
                            Order List
                            <v-icon class="ml-2"  >mdi-cart</v-icon>
                        </v-btn>
                        <v-btn :loading="DOWNLOADING_ORDERS?true:false" tile small color="secondary" @click="REFRESH_ORDERS()">
                            <!-- <v-icon  size="18"  color="white" class="mr-1">{{ DOWNLOADED_ORDERS?'mdi-lightbulb':'mdi-lightbulb-off' }}</v-icon> -->
                            Refresh 
                            <v-icon class="ml-2" >mdi-refresh</v-icon>
                        </v-btn> 
                        <v-menu bottom left  rounded="xl" >
                            <template v-slot:activator="{ on }">
                            <v-btn class="ml-2"
                                dark
                                icon
                                v-on="on" >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template> 
                            <v-card>
                                <v-card-title class="secondary white--text not-f4" >
                                    SELECT ACTION
                                </v-card-title> 
                                <v-list  > 
                                    <div v-for="(item, index) in TableActions" :key="index">
                                        <v-list-item  @click="TABLE_ACTION(item.action,item)" >
                                            <v-list-item-action class="mr-3" ><v-icon>{{item.icon}}</v-icon></v-list-item-action>
                                            <v-list-item-title class="">{{item.text}}</v-list-item-title>
                                        </v-list-item> 
                                    </div>
                                        
                                </v-list> 
                            </v-card>
                        </v-menu>
                    </v-card-title>
                    <mbs-page-tab
                        v-model="currentItemOrderTab"
                        color="b2"
                        text_color="black" dark
                        :items="TabOderItems"
                        @on_changed="CHANGED_ITEM_ORDER_TAB"
                        /> 
                    <v-card-text> 
                        <v-card-title>
                            {{MBS.actions.TEXT_UP(DATA.ITEMS.ASSISTANTS.names)}}  
                            <v-spacer></v-spacer> 
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                label="Search"
                                single-line  dense
                                clearable
                                hide-details
                            ></v-text-field>
                        </v-card-title>
                        <v-card outlined class="mbs-scroll-y"  :height="vsh-330"> 
                            <v-list v-if="MBS.actions.SIZE(AssistantItemOrders)!=0" class=""
                                :bench="2"
                                :items="AssistantItemOrders" 
                                item-height="70" >
                                <!-- <template v-slot="{ item,index }"> -->
                                <template v-for="(item,index) in AssistantItemOrders"> 
                                    <v-list-item @click="ON_VIEW_ASSISTANT_ORDER(item,index)" > 
                                        <v-list-item-action>  
                                            <v-layout row wrap> 
                                                <v-badge  style="height: 100%;"
                                                    class=""  
                                                    :value="true"  
                                                    :content="'new'"
                                                    :color="'green'"
                                                    :bordered="false" 
                                                    :overlap="true"  >
                                                    <v-avatar :class="item.expand?'':''"
                                                        size="40"   
                                                        rounded="lg" class="ml-2"
                                                        color="grey"  >
                                                        <v-img v-if="item.image"></v-img>
                                                        <v-icon v-else>mdi-cash-register</v-icon>
                                                    </v-avatar> 
                                                </v-badge>
                                            </v-layout>
                                        </v-list-item-action>  
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.name}} </v-list-item-title>
                                            <v-list-item-subtitle>Order(s): {{item.orders_size}}</v-list-item-subtitle>
                                            
                                        </v-list-item-content>
                                            
                                        <v-list-item-action><v-card color="transparent" flat width="100">
                                            <v-layout class="ma-0" align-baseline justify-end>
                                                <v-spacer></v-spacer>  
                                                <span class="not-f2 pr-1">MWK</span>
                                                <div class=" not-f5 font-weight-bold grey--text text--darken-2">
                                                    {{MBS.actions.money(item.total_sold_price)}}
                                                </div> 
                                            </v-layout>
                                        </v-card></v-list-item-action>  
                                        <v-list-item-action > 
                                            <v-btn  icon> 
                                                <v-icon >chevron_right</v-icon>
                                            </v-btn> 
                                        </v-list-item-action> 
                                    </v-list-item> 
                                    <v-divider  /> 
                                </template>
                            </v-list> 
                            <v-layout v-else fill-height justify-center align-center>
                                <v-chip close v-if="search" large class="px-5" @click:close="search=''">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f5 ml-2 grey--text" >No Order match "{{ search }}"</div>
                                </v-chip>
                                <v-chip v-else large class="px-5">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f6 ml-2 grey--text" >No Orders</div>
                                </v-chip>
                            </v-layout>
                        </v-card>  
                    </v-card-text>  
                </v-card> 
            </v-dialog> 

            <!-- View Selected Assistant ORDER  DIALOG -->
            <v-dialog
                v-model="dialog_view_selected_assistant_order"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="700px"
                transition="dialog-transition" > 
                <v-card rounded="lg" v-if="LiveSelectedAssistantOrder" > 

                    <!-- HEADERS -->
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span>SELECTED ASSISTANT ORDER(S)</span> 
                        </div> 
                        <v-chip small color="" class="ml-3">{{LiveSelectedAssistantOrder.orders_size}}</v-chip>
                        <v-spacer></v-spacer> 
                        <v-btn class="ml-9" @click="dialog_view_selected_assistant_order=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>    
                    
                    <v-card-text class="b1">
                        <v-card outlined class="mt-3">
                            <v-card-text> 
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-cash-register</v-icon>
                                    <div>Assistant:</div>
                                    <v-spacer/>
                                    <div class="ml-2 font-weight-bold">{{ LiveSelectedAssistantOrder.name }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Total Amount:</div>
                                    <v-spacer/>
                                    <span class="mt-2">MWK</span><div class="ml-1 font-weight-bold not-f7">{{ MBS.actions.money(LiveSelectedAssistantOrder.total_sold_price) }}</div>
                                </v-layout>
                            </v-card-text> 
                        </v-card>

                        <!-- LIST -->
                        <div class="pa-2">
                            <div class="font-weight-bold">Order list</div>
                            <v-card outlined class="mbs-scroll-y"  :height="vsh-405">
                                <v-list class=""
                                    :bench="2"
                                    :items="PageItemOrders" 
                                    item-height="70" > 
                                    <template v-for="(item,index) in LiveSelectedAssistantOrder.orders">
                                        
                                        <v-list-item @click="ON_VIEW_ORDER(item,index)"> 
                                            <v-list-item-action>  
                                                <v-layout row wrap> 
                                                    <v-avatar :class="item.expand?'':''"
                                                        size="40"   
                                                        rounded="lg" class="ml-2"
                                                        color="grey"  >
                                                        <v-img v-if="item.image"></v-img>
                                                        <v-icon v-else>shopping_cart</v-icon>
                                                    </v-avatar> 
                                                </v-layout>
                                            </v-list-item-action>  
                                            <v-list-item-content>
                                                <v-list-item-title> 
                                                    <span v-if="item?.till">{{ item.till_name }}: </span>
                                                    <span v-if="item?.waiter">{{item.waiter_name}}</span>
                                                    <span v-else-if="item?.teller">{{item.teller_name}}</span>
                                                </v-list-item-title>
                                                <!-- <v-list-item-title>{{item.waiter?item.waiter.name:'...'}}</v-list-item-title> -->
                                                <v-list-item-subtitle>Customer: {{item.customer?item.customer.name:'...'}}</v-list-item-subtitle> 
                                                <v-list-item-subtitle>Bill No: <strong>{{ item.key }}<span v-if="item.mkey">({{ item.mkey }})</span></strong> / Items: {{item.total_quantity_}}</v-list-item-subtitle> 
                                                <v-list-item-subtitle>{{item.local_created_at_}}</v-list-item-subtitle> 
                                            </v-list-item-content>
                                                
                                            <v-list-item-action><v-card color="transparent" flat width="100">
                                                <v-layout class="ma-0">
                                                    <v-spacer></v-spacer>
                                                    <div>
                                                        {{item.total_sold_price_}} 
                                                    </div>
                                                </v-layout>
                                            </v-card></v-list-item-action>  
                                            <v-list-item-action > 
                                                <v-btn  icon> 
                                                    <v-icon >chevron_right</v-icon>
                                                </v-btn> 
                                            </v-list-item-action> 
                                        </v-list-item> 
                                        <v-divider  /> 
                                    </template>
                                </v-list> 
                            </v-card> 
                        </div>
                    </v-card-text>  
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-layout row wrap justify-center align-center class="ma-0">
                            <v-btn-toggle  dark  class="" >
                                <v-btn  @click="EXIT_DIALOG()"  color="primary" value="exit">
                                    <span class="hidden-sm-and-down mx-3">EXIT</span>  
                                    <v-icon>mdi-exit-to-app</v-icon>
                                </v-btn>
                                
                                <v-btn  @click="CONFIRM_DELETE_MULTIPLE_ITEM_ORDERS({
                                        item_order:LiveSelectedCustomerOrder,
                                        type:'waiter',
                                        deleting_reason:'user_delete',
                                        notify:true
                                    })" color="primary" value="left">
                                    <span class="hidden-sm-and-down">Delete All</span> 
                                    <v-icon right>
                                        mdi-delete
                                    </v-icon>
                                </v-btn> 
                                
                                <v-btn @click="PRINT_ORDER_OPTION(LiveSelectedCustomerOrder,'print_customer_sale_orders')"  color="primary" value="center">
                                    <span class="hidden-sm-and-down">Print Summary</span> 
                                    <v-icon right>
                                        print
                                    </v-icon>
                                </v-btn>
    
                                <v-btn  @click="PAY_MULTIPLE_ITEM_ORDER(LiveSelectedCustomerOrder)"  color="primary" value="right">
                                    <span class="hidden-sm-and-down">Pay All</span> 
                                    <v-icon right>
                                        mdi-cash
                                    </v-icon>
                                </v-btn> 
                            </v-btn-toggle> 
                        </v-layout>

                    </v-card-actions>
                </v-card> 
            </v-dialog> 
            
            <!-- View Customer ORDERS DIALOG -->
            <v-dialog
                v-model="dialog_view_customers_orders"
                scrollable   tile 
                :persistent="false" :overlay="false"
                max-width="900px"
                transition="dialog-transition" >
                <v-card tile  v-if="dialog_view_customers_orders" >  
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span >CUSTOMER ORDER(S)</span> 
                        </div> 
                        <v-autocomplete v-if="show_device_selector" class="ml-2 mt-1" dark  
                                :label="!current_device?'Select device':''" 
                                prepend-inner-icon="mdi-cash-register" 
                                clearable 
                                filled
                                rounded 
                                :disabled="Assistant?true:false"
                                :items="CashupDevices"
                                :item-text="'name'"
                                item-value="key"
                                outlined
                                v-model="current_device"
                                ></v-autocomplete>
                        <v-spacer></v-spacer> 
                        <v-btn @click="CHANGE_ORDER_LIST_VIEW('list')" class="mx-1" tile small color="secondary" >
                            Order List
                            <v-icon class="ml-2"  >mdi-cart</v-icon>
                        </v-btn>
                        <v-btn :loading="DOWNLOADING_ORDERS?true:false" tile small color="secondary" @click="REFRESH_ORDERS()">
                            <!-- <v-icon  size="18"  color="white" class="mr-1">{{ DOWNLOADED_ORDERS?'mdi-lightbulb':'mdi-lightbulb-off' }}</v-icon> -->
                            Refresh 
                            <v-icon class="ml-2" >mdi-refresh</v-icon>
                        </v-btn> 
                        <v-menu bottom left  rounded="xl" >
                            <template v-slot:activator="{ on }">
                            <v-btn class="ml-2"
                                dark
                                icon
                                v-on="on" >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template> 
                            <v-card>
                                <v-card-title class="secondary white--text not-f4" >
                                    SELECT ACTION
                                </v-card-title> 
                                <v-list  > 
                                    <div v-for="(item, index) in TableActions" :key="index">
                                        <v-list-item  @click="TABLE_ACTION(item.action,item)" >
                                            <v-list-item-action class="mr-3" ><v-icon>{{item.icon}}</v-icon></v-list-item-action>
                                            <v-list-item-title class="">{{item.text}}</v-list-item-title>
                                        </v-list-item> 
                                    </div>
                                        
                                </v-list> 
                            </v-card>
                        </v-menu>
                    </v-card-title>
                    <mbs-page-tab
                        v-model="currentItemOrderTab"
                        color="b2"
                        text_color="black" dark
                        :items="TabOderItems"
                        @on_changed="CHANGED_ITEM_ORDER_TAB"
                        /> 
                    <v-card-text> 
                        <v-card-title>
                            {{MBS.actions.TEXT_UP(DATA.ITEMS.CUSTOMERS.names)}}  
                            <v-spacer></v-spacer> 
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                label="Search"
                                single-line  dense
                                clearable
                                hide-details
                            ></v-text-field>
                        </v-card-title>
                        <v-card outlined class="mbs-scroll-y"  :height="vsh-330"> 
                            <v-list v-if="MBS.actions.SIZE(CustomerItemOrders)!=0" class=""
                                :bench="2"
                                :items="CustomerItemOrders" 
                                item-height="70" >
                                <!-- <template v-slot="{ item,index }"> -->
                                <template v-for="(item,index) in CustomerItemOrders"> 
                                    <v-list-item @click="ON_VIEW_CUSTOMER_ORDER(item,index)" > 
                                        <v-list-item-action>  
                                            <v-layout row wrap> 
                                                <v-avatar :class="item.expand?'':''"
                                                    size="40"   
                                                    rounded="lg" class="ml-2"
                                                    color="grey"  >
                                                    <v-img v-if="item.image"></v-img>
                                                    <v-icon v-else>mdi-cart-heart</v-icon>
                                                </v-avatar> 
                                            </v-layout>
                                        </v-list-item-action>  
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.name}} </v-list-item-title>
                                            <v-list-item-subtitle>Order(s): {{item.orders_size}}</v-list-item-subtitle>
                                            
                                        </v-list-item-content>
                                            
                                        <v-list-item-action><v-card color="transparent" flat width="100">
                                            <v-layout class="ma-0" align-baseline justify-end>
                                                <v-spacer></v-spacer>  
                                                <span class="not-f2 pr-1">MWK</span>
                                                <div class=" not-f5 font-weight-bold grey--text text--darken-2">
                                                    {{MBS.actions.money(item.total_sold_price)}}
                                                </div> 
                                            </v-layout>
                                        </v-card></v-list-item-action>  
                                        <v-list-item-action > 
                                            <v-btn  icon> 
                                                <v-icon >chevron_right</v-icon>
                                            </v-btn> 
                                        </v-list-item-action> 
                                    </v-list-item> 
                                    <v-divider  /> 
                                </template>
                            </v-list> 
                            <v-layout v-else fill-height justify-center align-center>
                                <v-chip close v-if="search" large class="px-5" @click:close="search=''">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f5 ml-2 grey--text" >No Order match "{{ search }}"</div>
                                </v-chip>
                                <v-chip v-else large class="px-5">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f6 ml-2 grey--text" >No Orders</div>
                                </v-chip>
                            </v-layout>
                        </v-card> 
                        <!-- <v-layout row wrap class="ma-0 mt-3"> 
                            <v-btn   @click="dialog_view_customers_orders=false"
                                :loading="inputLoading" class="mr-2"
                                color="primary" height="50"  >
                                <div class="not-f5 mx-5">EXIT</div>
                                <v-icon>mdi-exit-to-app</v-icon>
                            </v-btn> 
                            <v-flex  >
                                <v-btn  @click="CONFIRM_DELETE_ALL_ITEM_ORDER({ 
                                    deleting_reason:'user_delete',
                                    notify:true
                                })" 
                                    type="submit"   
                                    :loading="inputLoading"
                                    color="secondary" height="50" width="100%" >
                                    <div class="not-f5">
                                        <v-icon class="pr-1 pb-1">mdi-delete</v-icon>
                                        Delete All
                                    </div>
                                </v-btn>      
                            </v-flex>    
                        </v-layout> -->
                    </v-card-text>  
                </v-card> 
            </v-dialog> 

            <!-- View Selected CUSTOMER ORDER  DIALOG -->
            <v-dialog
                v-model="dialog_view_selected_customer_order"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="700px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="LiveSelectedCustomerOrder" > 

                    <!-- HEADERS -->
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span>SELECTED CUSTOMER ORDER(S)</span> 
                        </div> 
                        <v-chip small color="" class="ml-3">{{LiveSelectedCustomerOrder.orders_size}}</v-chip>
                        <v-spacer></v-spacer> 
                        <v-btn class="ml-9" @click="dialog_view_selected_customer_order=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>    
                    
                    <v-card-text class="b1">
                        <v-card outlined class="mt-3">
                            <v-card-text> 
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-account</v-icon>
                                    <div>Customer:</div>
                                    <v-spacer/>
                                    <div class="ml-2 font-weight-bold">{{ LiveSelectedCustomerOrder.name }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Total Amount:</div>
                                    <v-spacer/>
                                    <span class="mt-2">MWK</span><div class="ml-1 font-weight-bold not-f7">{{ MBS.actions.money(LiveSelectedCustomerOrder.total_sold_price) }}</div>
                                </v-layout>
                            </v-card-text> 
                        </v-card>

                        <!-- LIST -->
                        <div class="pa-2">
                            <div class="font-weight-bold">Order list</div>
                            <v-card outlined class="mbs-scroll-y"  :height="vsh-405">
                                <v-list class=""
                                    :bench="2"
                                    :items="PageItemOrders" 
                                    item-height="70" > 
                                    <template v-for="(item,index) in LiveSelectedCustomerOrder.orders">
                                        
                                        <v-list-item @click="ON_VIEW_ORDER(item,index)"> 
                                            <v-list-item-action>  
                                                <v-layout row wrap> 
                                                    <v-avatar :class="item.expand?'':''"
                                                        size="40"   
                                                        rounded="lg" class="ml-2"
                                                        color="grey"  >
                                                        <v-img v-if="item.image"></v-img>
                                                        <v-icon v-else>shopping_cart</v-icon>
                                                    </v-avatar> 
                                                </v-layout>
                                            </v-list-item-action>  
                                            <v-list-item-content>
                                                <v-list-item-title> 
                                                    <span v-if="item?.till">{{ item.till_name }}: </span>
                                                    <span v-if="item?.waiter">{{item.waiter_name}}</span>
                                                    <span v-else-if="item?.teller">{{item.teller_name}}</span>
                                                </v-list-item-title>
                                                <!-- <v-list-item-title>{{item.waiter?item.waiter.name:'...'}}</v-list-item-title> -->
                                                <v-list-item-subtitle>Customer: {{item.customer?item.customer.name:'...'}}</v-list-item-subtitle> 
                                                <v-list-item-subtitle>Bill No: <strong>{{ item.key }}<span v-if="item.mkey">({{ item.mkey }})</span></strong> / Items: {{item.total_quantity_}}</v-list-item-subtitle> 
                                                <v-list-item-subtitle>{{item.local_created_at_}}</v-list-item-subtitle> 
                                            </v-list-item-content>
                                                
                                            <v-list-item-action><v-card color="transparent" flat width="100">
                                                <v-layout class="ma-0">
                                                    <v-spacer></v-spacer>
                                                    <div>
                                                        {{item.total_sold_price_}} 
                                                    </div>
                                                </v-layout>
                                            </v-card></v-list-item-action>  
                                            <v-list-item-action > 
                                                <v-btn  icon> 
                                                    <v-icon >chevron_right</v-icon>
                                                </v-btn> 
                                            </v-list-item-action> 
                                        </v-list-item> 
                                        <v-divider  /> 
                                    </template>
                                </v-list> 
                            </v-card> 
                        </div>
                    </v-card-text>  
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-layout row wrap justify-center align-center class="ma-0">
                            <v-btn-toggle  dark  class="" >
                                <v-btn  @click="EXIT_DIALOG()"  color="primary" value="exit">
                                    <span class="hidden-sm-and-down mx-3">EXIT</span>  
                                    <v-icon>mdi-exit-to-app</v-icon>
                                </v-btn>
                                
                                <v-btn  @click="CONFIRM_DELETE_MULTIPLE_ITEM_ORDERS({
                                        item_order:LiveSelectedCustomerOrder,
                                        type:'waiter',
                                        deleting_reason:'user_delete',
                                        notify:true
                                    })" color="primary" value="left">
                                    <span class="hidden-sm-and-down">Delete All</span> 
                                    <v-icon right>
                                        mdi-delete
                                    </v-icon>
                                </v-btn> 
                                
                                <v-btn @click="PRINT_ORDER_OPTION(LiveSelectedCustomerOrder,'print_customer_sale_orders')"  color="primary" value="center">
                                    <span class="hidden-sm-and-down">Print Summary</span> 
                                    <v-icon right>
                                        print
                                    </v-icon>
                                </v-btn>
    
                                <v-btn  @click="PAY_MULTIPLE_ITEM_ORDER(LiveSelectedCustomerOrder)"  color="primary" value="right">
                                    <span class="hidden-sm-and-down">Pay All</span> 
                                    <v-icon right>
                                        mdi-cash
                                    </v-icon>
                                </v-btn> 
                            </v-btn-toggle> 
                        </v-layout>

                    </v-card-actions>
                </v-card> 
            </v-dialog> 
            
            <!-- View TABLE ORDERS DIALOG -->
            <v-dialog
                v-model="dialog_view_table_orders"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="900px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_view_table_orders" > 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span >TABLES ORDER(S)</span> 
                        </div> 
                        <v-autocomplete class="ml-2 " dark  
                            label=" Orders from Others"
                            prepend-inner-icon="mdi-cash-register"
                            auto-select-first
                            clearable
                            dense
                            filled
                            rounded
                            small-chips>
                        </v-autocomplete>
                        <v-spacer></v-spacer> 
                        <v-btn @click="CHANGE_ORDER_LIST_VIEW('list')" class="mx-1" tile small color="secondary" >
                            Order List
                            <v-icon class="ml-2"  >mdi-cart</v-icon>
                        </v-btn>
                        <v-btn :loading="DOWNLOADING_ORDERS?true:false" tile small color="secondary" @click="REFRESH_ORDERS()">
                            <!-- <v-icon  size="18"  color="white" class="mr-1">{{ DOWNLOADED_ORDERS?'mdi-lightbulb':'mdi-lightbulb-off' }}</v-icon> -->
                            Refresh 
                            <v-icon class="ml-2" >mdi-refresh</v-icon>
                        </v-btn> 
                        <v-menu bottom left  rounded="xl" >
                            <template v-slot:activator="{ on }">
                            <v-btn class="ml-2"
                                dark
                                icon
                                v-on="on" >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template> 
                            <v-card>
                                <v-card-title class="secondary white--text not-f4" >
                                    SELECT ACTION
                                </v-card-title> 
                                <v-list  > 
                                    <div v-for="(item, index) in TableActions" :key="index">
                                        <v-list-item  @click="TABLE_ACTION(item.action,item)" >
                                            <v-list-item-action class="mr-3" ><v-icon>{{item.icon}}</v-icon></v-list-item-action>
                                            <v-list-item-title class="">{{item.text}}</v-list-item-title>
                                        </v-list-item> 
                                    </div>
                                        
                                </v-list> 
                            </v-card>
                        </v-menu>
                    </v-card-title>
                    <mbs-page-tab
                        v-model="currentItemOrderTab"
                        color="b2"
                        text_color="black" dark
                        :items="TabOderItems"
                        @on_changed="CHANGED_ITEM_ORDER_TAB"/> 
                    <v-card-text> 
                        <v-card-title>
                            TABLES
                            <v-spacer></v-spacer> 
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                label="Search"
                                single-line dense
                                clearable
                                hide-details
                            ></v-text-field>
                        </v-card-title>
                        <v-card outlined class="mbs-scroll-y"  :height="vsh-330"> 
                            <v-list v-if="MBS.actions.SIZE(TableItemOrders)!=0" class=""
                                :bench="2"
                                :items="TableItemOrders" 
                                item-height="70" >
                                <!-- <template v-slot="{ item,index }"> -->
                                <template v-for="(item,index) in TableItemOrders"> 
                                    <v-list-item @click="ON_VIEW_TABLE_ORDER(item,index)" :key="index"> 
                                        <v-list-item-action>  
                                            <v-layout row wrap> 
                                                <v-avatar :class="item.expand?'':''"
                                                    size="40"   
                                                    rounded="lg" class="ml-2"
                                                    color="grey"  >
                                                    <v-img v-if="item.image"></v-img>
                                                    <v-icon v-else>mdi-table-chair</v-icon>
                                                </v-avatar> 
                                            </v-layout>
                                        </v-list-item-action>  
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.name}} </v-list-item-title>
                                            <v-list-item-subtitle>Order(s): {{item.orders_size}}</v-list-item-subtitle>
                                            
                                        </v-list-item-content>
                                            
                                        <v-list-item-action><v-card color="transparent" flat width="100">
                                            <v-layout class="ma-0" align-baseline justify-end>
                                                <v-spacer></v-spacer>  
                                                <span class="not-f2 pr-1">MWK</span>
                                                <div class=" not-f5 font-weight-bold grey--text text--darken-2">{{MBS.actions.money(item.total_sold_price)}}</div>
                                                
                                            </v-layout>
                                        </v-card></v-list-item-action>  
                                        <v-list-item-action > 
                                            <v-btn  icon> 
                                                <v-icon >chevron_right</v-icon>
                                            </v-btn> 
                                        </v-list-item-action> 
                                    </v-list-item> 
                                    <v-divider  :key="' '+index"/> 
                                </template>
                            </v-list> 
                            <v-layout v-else fill-height justify-center align-center>
                                <v-chip close v-if="search" large class="px-5" @click:close="search=''">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f5 ml-2 grey--text" >No Order match "{{ search }}"</div>
                                </v-chip>
                                <v-chip v-else large class="px-5">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f6 ml-2 grey--text" >No Orders</div>
                                </v-chip>
                            </v-layout>
                        </v-card> 
                        <!-- <v-layout row wrap class="ma-0 mt-3"> 
                            <v-btn   @click="dialog_view_table_orders=false"
                                :loading="inputLoading" class="mr-2"
                                color="primary" height="50"  >
                                <div class="not-f5 mx-5">EXIT</div>
                                <v-icon>mdi-exit-to-app</v-icon>
                            </v-btn> 
                            <v-flex  >
                                <v-btn  @click="CONFIRM_DELETE_ALL_ITEM_ORDER({ 
                                    deleting_reason:'user_delete',
                                    notify:true
                                })" 
                                    type="submit"   
                                    :loading="inputLoading"
                                    color="secondary" height="50" width="100%" >
                                    <div class="not-f5">
                                        <v-icon class="pr-1 pb-1">mdi-delete</v-icon>
                                        Delete All
                                    </div>
                                </v-btn>      
                            </v-flex>    
                        </v-layout> -->
                    </v-card-text>  
                </v-card> 
            </v-dialog> 

            <!-- View Selected TABLE ORDER  DIALOG -->
            <v-dialog
                v-model="dialog_view_selected_table_order"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="700px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="LiveSelectedTableOrder" > 

                    <!-- HEADERS -->
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span>SELECTED TABLE ORDER(S)</span> 
                        </div> 
                        <v-chip small color="" class="ml-3">{{LiveSelectedTableOrder.orders_size}}</v-chip>
                        <v-spacer></v-spacer> 
                        <v-btn class="ml-9" @click="dialog_view_selected_table_order=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>    
                    
                    <v-card-text class="b1">
                        <v-card outlined class="mt-3">
                            <v-card-text> 
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-table-chair</v-icon>
                                    <div>Table:</div>
                                    <v-spacer/>
                                    <div class="ml-2 font-weight-bold">{{ LiveSelectedTableOrder.name }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Total Amount:</div>
                                    <v-spacer/>
                                    <span class="mt-2">MWK</span><div class="ml-1 font-weight-bold not-f7">{{ MBS.actions.money(LiveSelectedTableOrder.total_sold_price) }}</div>
                                </v-layout>
                            </v-card-text> 
                        </v-card>

                        <!-- LIST -->
                        <div class="pa-2">
                            <div class="font-weight-bold">Order list</div>
                            <v-card outlined class="mbs-scroll-y"  :height="vsh-405">
                                <v-list class=""
                                    :bench="2"
                                    :items="PageItemOrders" 
                                    item-height="70" > 
                                    <template v-for="(item,index) in LiveSelectedTableOrder.orders">
                                        
                                        <v-list-item @click="ON_VIEW_ORDER(item,index)"> 
                                            <v-list-item-action>  
                                                <v-layout row wrap> 
                                                    <v-avatar :class="item.expand?'':''"
                                                        size="40"   
                                                        rounded="lg" class="ml-2"
                                                        color="grey"  >
                                                        <v-img v-if="item.image"></v-img>
                                                        <v-icon v-else>shopping_cart</v-icon>
                                                    </v-avatar> 
                                                </v-layout>
                                            </v-list-item-action>  
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.waiter?item.waiter.name:'...'}}</v-list-item-title>
                                                <v-list-item-subtitle>Customer: {{item.customer?item.customer.name:'...'}}</v-list-item-subtitle> 
                                                <v-list-item-subtitle>Bill No: <strong>{{ item.key }}<span v-if="item.mkey">({{ item.mkey }})</span></strong> / Items: {{item.total_quantity_}}</v-list-item-subtitle> 
                                                <v-list-item-subtitle>{{item.local_created_at_}}</v-list-item-subtitle> 
                                            </v-list-item-content>
                                                
                                            <v-list-item-action><v-card color="transparent" flat width="100">
                                                <v-layout class="ma-0">
                                                    <v-spacer></v-spacer>
                                                    <div>
                                                        {{item.total_sold_price_}} 
                                                    </div>
                                                </v-layout>
                                            </v-card></v-list-item-action>  
                                            <v-list-item-action > 
                                                <v-btn  icon> 
                                                    <v-icon >chevron_right</v-icon>
                                                </v-btn> 
                                            </v-list-item-action> 
                                        </v-list-item> 
                                        <v-divider  /> 
                                    </template>
                                </v-list> 
                            </v-card> 
                        </div>
                    </v-card-text>  
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-layout row wrap justify-center align-center class="ma-0">
                            <v-btn-toggle  dark  class="" >
                                <v-btn  @click="EXIT_DIALOG()"  color="primary" value="exit">
                                    <span class="hidden-sm-and-down mx-3">EXIT</span>  
                                    <v-icon>mdi-exit-to-app</v-icon>
                                </v-btn>
                                
                                <v-btn  @click="CONFIRM_DELETE_MULTIPLE_ITEM_ORDERS({
                                        item_order:LiveSelectedTableOrder,
                                        type:'waiter',
                                        deleting_reason:'user_delete',
                                        notify:true
                                    })" color="primary" value="left">
                                    <span class="hidden-sm-and-down">Delete All</span> 
                                    <v-icon right>
                                        mdi-delete
                                    </v-icon>
                                </v-btn> 
                                
                                <v-btn @click="PRINT_ORDER_OPTION(LiveSelectedTableOrder,'print_table_sale_orders')"  color="primary" value="center">
                                    <span class="hidden-sm-and-down">Print Summary</span> 
                                    <v-icon right>
                                        print
                                    </v-icon>
                                </v-btn>
    
                                <v-btn  @click="PAY_MULTIPLE_ITEM_ORDER(LiveSelectedTableOrder)"  color="primary" value="right">
                                    <span class="hidden-sm-and-down">Pay All</span> 
                                    <v-icon right>
                                        mdi-cash
                                    </v-icon>
                                </v-btn> 
                            </v-btn-toggle> 
                        </v-layout>

                    </v-card-actions>
                </v-card> 
            </v-dialog> 
            
            <!-- View WAITERS ORDERS DIALOG -->
            <v-dialog
                v-model="dialog_view_waiter_orders"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="900px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_view_waiter_orders" > 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span >WAITERS ORDER(S)</span> 
                        </div> 
                        <v-autocomplete class="ml-2 " dark  
                            label=" Orders from Others"
                            prepend-inner-icon="mdi-cash-register"
                            auto-select-first
                            clearable
                            dense
                            filled
                            rounded
                            small-chips>
                        </v-autocomplete>
                        <v-spacer></v-spacer> 
                        <v-btn @click="CHANGE_ORDER_LIST_VIEW('list')" class="mx-1" tile small color="secondary" >
                            Order List
                            <v-icon class="ml-2"  >mdi-cart</v-icon>
                        </v-btn>
                        <v-btn :loading="DOWNLOADING_ORDERS?true:false" tile small color="secondary" @click="REFRESH_ORDERS()">
                            <!-- <v-icon  size="18"  color="white" class="mr-1">{{ DOWNLOADED_ORDERS?'mdi-lightbulb':'mdi-lightbulb-off' }}</v-icon> -->
                            Refresh 
                            <v-icon class="ml-2" >mdi-refresh</v-icon>
                        </v-btn> 
                        <v-menu bottom left  rounded="xl" >
                            <template v-slot:activator="{ on }">
                            <v-btn class="ml-2"
                                dark
                                icon
                                v-on="on" >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template> 
                            <v-card>
                                <v-card-title class="secondary white--text not-f4" >
                                    SELECT ACTION
                                </v-card-title> 
                                <v-list  > 
                                    <div v-for="(item, index) in TableActions" :key="index">
                                        <v-list-item  @click="TABLE_ACTION(item.action,item)" >
                                            <v-list-item-action class="mr-3" ><v-icon>{{item.icon}}</v-icon></v-list-item-action>
                                            <v-list-item-title class="">{{item.text}}</v-list-item-title>
                                        </v-list-item> 
                                    </div>
                                        
                                </v-list> 
                            </v-card>
                        </v-menu>
                    </v-card-title>
                    <mbs-page-tab
                        v-model="currentItemOrderTab"
                        color="b2" 
                        text_color="black" dark
                        :items="TabOderItems"
                        @on_changed="CHANGED_ITEM_ORDER_TAB"/> 
                    <v-card-text> 
                        <v-card-title>
                            WAITERS
                            <v-spacer></v-spacer> 
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                label="Search"
                                single-line dense
                                clearable
                                hide-details
                            ></v-text-field>
                        </v-card-title>
                        <v-card outlined class="mbs-scroll-y"  :height="vsh-330"> 
                            <v-list v-if="MBS.actions.SIZE(WaiterItemOrders)!=0" class=""
                                :bench="2"
                                :items="WaiterItemOrders" 
                                item-height="70" >
                                <!-- <template v-slot="{ item,index }"> -->
                                <template v-for="(item,index) in WaiterItemOrders"> 
                                    <v-list-item @click="ON_VIEW_WAITER_ORDER(item,index)" :key="index"> 
                                        <v-list-item-action>  
                                            <v-layout row wrap> 
                                                <v-avatar :class="item.expand?'':''"
                                                    size="40"   
                                                    rounded="lg" class="ml-2"
                                                    color="grey"  >
                                                    <v-img v-if="item.image"></v-img>
                                                    <v-icon v-else>mdi-face-agent</v-icon>
                                                </v-avatar> 
                                            </v-layout>
                                        </v-list-item-action>  
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.name}} </v-list-item-title>
                                            <v-list-item-subtitle>Order(s): {{item.orders_size}}</v-list-item-subtitle>
                                            
                                        </v-list-item-content>
                                            
                                        <v-list-item-action><v-card color="transparent" flat width="100">
                                            <v-layout class="ma-0" align-baseline justify-end>
                                                <v-spacer></v-spacer>  
                                                <span class="not-f2 pr-1">MWK</span>
                                                <div class=" not-f5 font-weight-bold grey--text text--darken-2">{{MBS.actions.money(item?.total_sold_price)}}</div>
                                                
                                            </v-layout>
                                        </v-card></v-list-item-action>  
                                        <v-list-item-action > 
                                            <v-btn  icon> 
                                                <v-icon >chevron_right</v-icon>
                                            </v-btn> 
                                        </v-list-item-action> 
                                    </v-list-item> 
                                    <v-divider  :key="' '+index"/> 
                                </template>
                            </v-list> 
                            <v-layout v-else fill-height justify-center align-center>
                                <v-chip close v-if="search" large class="px-5" @click:close="search=''">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f5 ml-2 grey--text" >No Order match "{{ search }}"</div>
                                </v-chip>
                                <v-chip v-else large class="px-5">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f6 ml-2 grey--text" >No Orders</div>
                                </v-chip>
                            </v-layout>
                        </v-card> 
                        <!-- <v-layout row wrap class="ma-0 mt-3"> 
                            <v-btn   @click="dialog_view_waiter_orders=false"
                                :loading="inputLoading" class="mr-2"
                                color="primary" height="50"  >
                                <div class="not-f5 mx-5">EXIT</div>
                                <v-icon>mdi-exit-to-app</v-icon>
                            </v-btn> 
                            <v-flex  >
                                <v-btn  @click="CONFIRM_DELETE_ALL_ITEM_ORDER({
                                    item_order:LiveSelectedWaiterOrder,
                                    type:'waiter',
                                    deleting_reason:'user_delete',
                                    notify:true
                                })" 
                                    type="submit"   
                                    :loading="inputLoading"
                                    color="secondary" height="50" width="100%" >
                                    <div class="not-f5">
                                        <v-icon class="pr-1 pb-1">mdi-delete</v-icon>
                                        Delete All
                                    </div>
                                </v-btn>      
                            </v-flex>    
                        </v-layout> -->
                    </v-card-text>  
                </v-card> 
            </v-dialog> 

            <!-- View Selected WAITER ORDER  DIALOG -->
            <v-dialog
                v-model="dialog_view_selected_waiter_order"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="700px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="LiveSelectedWaiterOrder" > 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span>SELECTED WAITER ORDER</span> 
                        </div> 
                        <v-chip small color="" class="ml-3">{{LiveSelectedWaiterOrder.orders_size}}</v-chip>
                        <v-spacer></v-spacer> 
                        <v-btn class="ml-9" @click="dialog_view_selected_waiter_order=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>    
                    
                    <v-card-text class="b1">
                        <v-card outlined class="mt-3">
                            <v-card-text> 
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-face-agent</v-icon>
                                    <div>Waiter:</div>
                                    <v-spacer/>
                                    <div class="ml-2 font-weight-bold">{{ LiveSelectedWaiterOrder.name }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Total Amount:</div>
                                    <v-spacer/>
                                    <span class="mt-2">MWK</span><div class="ml-1 font-weight-bold not-f7">{{ MBS.actions.money(LiveSelectedWaiterOrder.total_sold_price) }}</div>
                                </v-layout>
                            </v-card-text> 
                        </v-card>
                        <div class="pa-2">
                            <div class="font-weight-bold">Order list</div>
                            <v-card outlined class="mbs-scroll-y"  :height="vsh-415">
                                <v-list class=""
                                    :bench="2"
                                    :items="PageItemOrders" 
                                    item-height="70" > 
                                    <template v-for="(item,index) in LiveSelectedWaiterOrder.orders">
                                        
                                        <v-list-item @click="ON_VIEW_ORDER(item,index)"> 
                                            <v-list-item-action>  
                                                <v-layout row wrap> 
                                                    <v-avatar :class="item.expand?'':''"
                                                        size="40"   
                                                        rounded="lg" class="ml-2"
                                                        color="grey"  >
                                                        <v-img v-if="item.image"></v-img>
                                                        <v-icon v-else>shopping_cart</v-icon>
                                                    </v-avatar> 
                                                </v-layout>
                                            </v-list-item-action>  
                                            <v-list-item-content>
                                                <!-- <v-list-item-title>{{item.waiter?item.waiter.name:'...'}}</v-list-item-title> -->
                                                <v-list-item-title>{{item.table?item.table.name:'...'}}</v-list-item-title>
                                                <v-list-item-subtitle>{{item.customer?item.customer.name:'...'}}</v-list-item-subtitle>
                                                <v-list-item-subtitle>Bill No: <strong>{{ item.key }}<span v-if="item.mkey">({{ item.mkey }})</span></strong> / Items: {{item.total_quantity_}}</v-list-item-subtitle> 
                                                <v-list-item-subtitle>{{item.local_created_at_}}</v-list-item-subtitle> 
                                            </v-list-item-content>
                                                
                                            <v-list-item-action><v-card color="transparent" flat width="100">
                                                <v-layout class="ma-0">
                                                    <v-spacer></v-spacer>
                                                    <div>
                                                        {{item.total_sold_price_}} 
                                                    </div>
                                                </v-layout>
                                            </v-card></v-list-item-action>  
                                            <v-list-item-action > 
                                                <v-btn  icon> 
                                                    <v-icon >chevron_right</v-icon>
                                                </v-btn> 
                                            </v-list-item-action> 
                                        </v-list-item> 
                                        <v-divider  /> 
                                    </template>
                                </v-list> 
                            </v-card> 
                        </div>
                    </v-card-text>  
                    <v-card-actions>
                        <v-spacer/> 

                        <v-btn-toggle  dark  class="mb-2 mr-2" >
                            <v-btn  @click="EXIT_DIALOG()"  color="primary" value="exit">
                                <span class="hidden-sm-and-down mx-3">EXIT</span>  
                                <v-icon>mdi-exit-to-app</v-icon>
                            </v-btn>
                            
                            <v-btn  @click="CONFIRM_DELETE_MULTIPLE_ITEM_ORDERS({
                                item_order:LiveSelectedWaiterOrder,
                                type:'waiter',
                                deleting_reason:'user_delete',
                                notify:true
                            })"  color="primary" value="left">
                                <span class="hidden-sm-and-down">Delete All</span> 
                                <v-icon right>
                                    mdi-delete
                                </v-icon>
                            </v-btn> 
                            
                            <v-btn @click="PRINT_ORDER_OPTION(LiveSelectedWaiterOrder,'print_waiter_sale_orders')"  color="primary" value="center">
                                <span class="hidden-sm-and-down">Print Summary</span> 
                                <v-icon right>
                                    print
                                </v-icon>
                            </v-btn>

                            <v-btn  @click="PAY_MULTIPLE_ITEM_ORDER(LiveSelectedWaiterOrder)"  color="primary" value="right">
                                <span class="hidden-sm-and-down">Pay All</span> 
                                <v-icon right>
                                    mdi-cash
                                </v-icon>
                            </v-btn> 
                        </v-btn-toggle>
                    </v-card-actions>
                </v-card> 
            </v-dialog> 

            <!-- //print -->
            <div v-if="auto_print.printing"> 
                <mbs-auto-print 
                    @finish_print="FINISH_PRINT"
                    :show="true"  
                    :auto_print="auto_print.auto"
                    :auto_printer_name="auto_print.auto_printer_name"
                    :com_printer="auto_print.com_printer"  
                    :data="auto_print.print_data"
                    :type="auto_print.print_type"
                    :title="auto_print.print_title"
                    :printing_action="auto_print.printing_action"
                    :display_option="auto_print.display_option" 
                    />  
            </div> 
        </div>
    </div>
</template> 
<script>
let PAGE_NAME = 'ORDER-OPTION'
import DATA from "../../plugins/DATA"
import DB from "../../plugins/DB"
import {mapState,mapGetters } from "vuex" 
import { toNumber } from '../../plugins/formatNumber'
export default { 
    props:['show','disabled','hight','width','dense','selected_items','total_price','waiter_uid','item_order','edit_order_items','DOWNLOADING_ORDERS','DOWNLOADED_ORDERS'],
    data(){
        return{  
            body_height:0,
            dialog:false, 
            dialog_new_order_option:false, 
            dialog_update_order_option:false, 
            dialog_create_quotation:false, 

            dialog_view_orders_list:false, 
            dialog_view_assistant_orders:false, 
            dialog_view_customers_orders:false, 
            dialog_view_options_orders:false, 
            dialog_view_table_orders:false, 

            dialog_view_selected_assistant_order:false, 
            dialog_view_selected_customer_order:false, 
            dialog_view_selected_table_order:false, 
            dialog_view_waiter_orders:false, 
            dialog_view_selected_waiter_order:false, 
            dialog_view_selected_option_order:false, 
            dialog_view_order:false, 

            menu_send_and_print_option:false,
            menu_select_order_docket:false,
            menu_select_order_option:false,
            menu_update_order_option:false,
            menu_edit_order_option:false,
            menu_update_existing_order_option:false,
            currentOrderOptionTab:null,  
            currentItemOrderTab:null,  
            current_device:null,  
            show_device_selector:true,  
            input:{}, 
            inputLoading:false, 
            search:null, 
            menuSelect:false,
            PUT_AND_PRINT:false,
            ORDER_PRINT_OPTIONS:null,
            SELECTED_ORDER_OPTION:null,
            SELECTED_ORDER:null, 
            SELECTED_ASSISTANT_ORDER:null, 
            SELECTED_CUSTOMER_ORDER:null, 
            SELECTED_TABLE_ORDER:null, 
            SELECTED_WAITER_ORDER:null, 
            DATA:DATA,  
            clearing_item_orders:false,

            auto_print:{
                auto:false,
                printing:false, 
                print_data:null,
                print_type:null,
                com_printer:false, 
                print_title:null, 
                auto_printer_name:null,
                printing_action:null, 
                display_option:'',  
            },
            
        } 
    }, 
    created() {
        try {
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
            this.MBS.events.$on('ON_SUBMITTED_ITEM_ORDERS', this.ON_SUBMITTED_ITEM_ORDERS);   
            this.MBS.events.$on('ON_VIEW_ORDERS', this.ON_VIEW_ORDERS);   
            this.MBS.events.$on('ON_VIEW_BILL', this.ON_VIEW_BILL);   

            this.MBS.events.$on('CONFIRM_DELETE_ITEM_ORDER', this.CONFIRM_DELETE_ITEM_ORDER);   
            this.MBS.events.$on('CONFIRM_DELETE_ALL_ITEM_ORDER', this.CONFIRM_DELETE_ALL_ITEM_ORDER);   
            this.MBS.events.$on('DELETE_ITEM_ORDER', this.DELETE_ITEM_ORDER);   
            this.MBS.events.$on('DELETE_MULTIPLE_ITEM_ORDERS', this.DELETE_MULTIPLE_ITEM_ORDERS);   
            this.MBS.events.$on('DELETE_ALL_ITEM_ORDER', this.DELETE_ALL_ITEM_ORDER);   
            
        } catch (error) {
            this.MBS.actions.error({
                error:error,
                from:'created',
                page:PAGE_NAME, 
            }) 
        }
    },
    mounted(){ 
        this.INITIATE_DEVICE(this.CurrentTill) 
    },
    computed:{
        route(){
            return this.$route
        },
        vs(){

            return this.$vuetify.breakpoint
        }, 
        vsh(){
            return this.vs.height
        }, 


        vsb(){ 
            let body_height = this.body_height 
            let height = this.vs.height 
            body_height = this.$refs.body_height?this.$refs.body_height.$el.clientHeight:body_height;
            let diff = height-body_height
            return {
                height:height,
                body_height:body_height,
                diff:diff,
                scroll:body_height?diff>180?false:true:false,
            }
        },
        ...mapState({
            loading:state=>state.load.loading,
            processes:state=>state.load.processes,
            responses:state=>state.load.responses,
        }),
        ...mapState({
            us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
            ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
            K_CONNECTOR: state=> state.items['K_CONNECTOR'],
        }),
        ...mapState({
            CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
            CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
            Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
            JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
            CompanyUsers: state=> state.users[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()],
            JoinedCompanyUsers: state=> state.join[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()],  
            Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
            JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
            ItemOrders: state=> state.items[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
            JoinedItemOrders: state=> state.join[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
            LanItemOrders: state=> state.lan["LAN_ITEM_ORDERS"],
            JoinedLanItemOrders: state=> state.join["LAN_ITEM_ORDERS"],
            LocalItemOrders: state=> state.items[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
            JoinedLocalItemOrders: state=> state.join[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
            ItemOrderOptions: state=> state.items[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()],
            JoinedItemOrderOptions: state=> state.join[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()], 
            Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
            JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()], 
            ItemDepartments: state=> state.items[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()],
            JoinedItemDepartments: state=> state.join[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()], 
             
            Customers: state=> state.items[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
            JoinedCustomers: state=> state.join[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
            CustomerGroups: state=> state.items[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()], 
            JoinedCustomerGroups: state=> state.join[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()], 
            SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
            JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
            LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            JoinedLocalCashups: state=> state.join[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()],
            Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
            JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()],  

            LocalCashups: state=> state.locals[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()],
 
            OrderOptionTypes: state=> state.app['ODER_OPTION_TYPES'],
            Devices: state=> state.items[(DATA.ITEMS.DEVICES.values).toUpperCase()],
            JoinedDevices: state=> state.join[(DATA.ITEMS.DEVICES.values).toUpperCase()],
                
        }), 
        CustomersData(){
            const Customers = this.Customers
            const JoinedCustomers = this.JoinedCustomers  
            return JoinedCustomers?JoinedCustomers:Customers
        }, 
        CustomerGroupsData(){
            const CustomerGroups = this.CustomerGroups
            const JoinedCustomerGroups = this.JoinedCustomerGroups  
            return JoinedCustomerGroups?JoinedCustomerGroups:CustomerGroups
        },
        OutletsData(){
            return this.JoinedOutlets?this.JoinedOutlets:this.Outlets
        }, 
        TillsData(){
            return this.JoinedTills?this.JoinedTills:this.Tills
        }, 
        ItemDepartmentsData(){  
            let ItemDepartments = this.ItemDepartments
            let JoinedItemDepartments = this.JoinedItemDepartments
            return  JoinedItemDepartments?JoinedItemDepartments:ItemDepartments 
                
        },
        HasDepartment(){
            return this.MBS.actions.SIZE(this.ItemDepartmentsData)
        },
        ItemOrderOptionsData(){   
            return this.JoinedItemOrderOptions?this.JoinedItemOrderOptions:this.ItemOrderOptions
        }, 
        ItemOrdersData(){    
            return this.JoinedItemOrders?this.JoinedItemOrders:this.ItemOrders
        },  
        LanItemOrdersData(){  
            const join = this.JoinedLanItemOrders
            const items = this.LanItemOrders
            const data = join?join:items 
            
            return data
        },
        LocalItemOrdersData(){  
            return this.JoinedLocalItemOrders?this.JoinedLocalItemOrders:this.LocalItemOrders
        },  
        AllItemOrders(){  
            const lan = this.LanItemOrdersData
            const local = this.LocalItemOrdersData
            const live = this.ItemOrdersData   
            const device_key = this.current_device   
            const find = key=>{
                if(!live){return null}
                return live.find(order=>order.key == key)
            } 
            let items = []
            // if (this.MBS.actions.SIZE(live)>0) {
            //     live.forEach(element => {
            //         const assistant_till_key = element.assistant_till_key?element.assistant_till_key:element.till_key
            //         if (assistant_till_key == device_key || device_key=="all") { 
            //             items.push({...element,new_order:true})
            //         }
                    
            //     });
            // }
            if (this.MBS.actions.SIZE(local)>0) {
                local.forEach(element => {
                    const assistant_till_key = element.assistant_till_key?element.assistant_till_key:element.till_key
                    if (assistant_till_key == device_key || device_key=="all") {
                        // const order_index =items.findIndex(order=>order.key == element.key)
                        // if (order_index==-1) {
                        //     items.push({...element,new_order:false})
                        // }else{
                        //     items[order_index] = {
                        //         ...items[order_index],
                        //         ...element, new_order:false
                        //     }
                        // } 
                        items.push({...element,new_order:false})
                    }
                });
            }
            if (this.MBS.actions.SIZE(live)>0) {
                live.forEach(element => {
                    const assistant_till_key = element.assistant_till_key?element.assistant_till_key:element.till_key
                    if (assistant_till_key == device_key || device_key=="all") {
                        const order_index =items.findIndex(order=>order.key == element.key)
                        if (order_index==-1) {
                            items.push({...element,new_order:true})
                        }else{
                            items[order_index] = {
                                ...items[order_index],
                                ...element, new_order:items[order_index]?.new_order
                            }
                        } 
                    }
                });
            }
            if (this.MBS.actions.SIZE(lan)>0) { 
                lan.forEach(element => { 
                    const assistant_till_key = element.assistant_till_key?element.assistant_till_key:element.till_key 
                    if (assistant_till_key == device_key || device_key=="all") {
                        const order_index =items.findIndex(order=>order.key == element.key)
                        if (order_index==-1) {
                            items.push({...element,new_order:true})
                        }else{
                            items[order_index] = {
                                ...items[order_index],
                                ...element,new_order:items[order_index]?.new_order
                            }
                        }  
                    }

                });
            }
            items = items.filter(order=>!order.deleted)
            if(!items){return null}
            const list = [...items]
 
            list.sort(this.MBS.actions.dynamicSort("key",false))     
            return list 
        }, 
        AllItemOrdersByWaiter(){
            const waiter = this.CurrentWaiter 
            const orders = this.AllItemOrders  
            let company_user_group_code = waiter?.company_user_group_code
            let company_user_group_name = waiter?.company_user_group_name
            let waiter_key = waiter?.key
            
            if (company_user_group_name != "Waiters") {
                return orders
            }
            if(!orders){return null}
            let filtered = orders.filter(order=>order.waiter_key == waiter_key) 
            return filtered

        },
        ActionAndProcessingOrderOption(){
            const options = this.ItemOrderOptionsData
            if(!options){return null}
            const items = options.filter(item=>item.type_key == "action_order"||item.type_key == "processing_order")
            return items
        },
        CashupDevices(){
            const tills = this.TillsData
            const cashup = this.LocalCashup
            const till_key = cashup?.till_key 
            const cashup_till_key = cashup?.cashup_till_key 
            const cashup_till_key_ = cashup_till_key?cashup_till_key:till_key
  
            if(!tills){return null}
            let items = [{name:"All",key:"all"}]
            tills.forEach(till => {
                const key = till?.key
                const cashier_till_key = till?.cashier_till_key
                const cashier_cashup_key_ = cashier_till_key?cashier_till_key:key
                if (cashier_cashup_key_ == cashup_till_key_) {
                    items.push(till)
                }
                
            }); 
            return items
        },
        SelectedOrderOption(){
            const selected_options = this.SELECTED_ORDER_OPTION
            const options = this.ActionAndProcessingOrderOption
            const input = this.input
            const assistant = this.Assistant
            const order_option_key = input?.order_option_key
            if(!options){return null}
            if(!assistant && selected_options){return selected_options}
            const items = options.find(item=>item.key==order_option_key)
            return items
        },
        AssistantItemOrders(){  
            const orders = this.AllItemOrdersByWaiter
            const search = this.search
            if(!orders){return null}
            let assistants = []
            orders.forEach(element => {
                const assistant = element.assistant_till?element.assistant_till:element.till?element.till:{name:"Unknown",key:"unknown"}
                const assistant_name = assistant?assistant.name:''
                const total_sold_price = this.toNumber(element.total_sold_price)
                const index = assistants.findIndex(item=>item.key==assistant.key)
                let include = true
                if (search && assistant_name) {
                    let name_index = this.MBS.actions.TEXT_UP0(assistant_name).indexOf(this.MBS.actions.TEXT_UP0(search))
                    if (name_index == -1) {
                        include = false
                    } 
                }

                if (include) {
                    if (index==-1) {
                        let assistant_orders = {
                            ...assistant,
                            total_sold_price:total_sold_price,
                            orders_size:1,
                            orders:[]
                        }
                        assistant_orders.orders.push(element)
                        assistants.push(assistant_orders)
                    } else {
                        assistants[index].orders.push(element)
                        assistants[index].orders_size++
                        assistants[index].total_sold_price = assistants[index].total_sold_price+total_sold_price  
                    } 
                }
            });  
            
            
            return assistants
            
        }, 
        CustomerItemOrders(){  
            const orders = this.AllItemOrdersByWaiter
            const search = this.search
            if(!orders){return null}
            let customers = []
            orders.forEach(element => {
                const customer = element.customer?element.customer:{name:"Unknown",key:"unknown"}
                const customer_name = customer?customer.name:''
                const total_sold_price = this.toNumber(element.total_sold_price)
                const index = customers.findIndex(item=>item.key==customer.key)
                let include = true
                if (search && customer_name) {
                    let name_index = this.MBS.actions.TEXT_UP0(customer_name).indexOf(this.MBS.actions.TEXT_UP0(search))
                    if (name_index == -1) {
                        include = false
                    } 
                }

                if (include) {
                    if (index==-1) {
                        let customer_orders = {
                            ...customer,
                            total_sold_price:total_sold_price,
                            orders_size:1,
                            orders:[]
                        }
                        customer_orders.orders.push(element)
                        customers.push(customer_orders)
                    } else {
                        customers[index].orders.push(element)
                        customers[index].orders_size++
                        customers[index].total_sold_price = customers[index].total_sold_price+total_sold_price  
                    } 
                }
            });  
            return customers
            
        }, 
        TableItemOrders(){  
            const orders = this.AllItemOrdersByWaiter
            const search = this.search
            if(!orders){return null}
            let tables = []
            orders.forEach(element => {
                const table = element.table?element.table:{name:"Unknown",key:"unknown"}
                const table_name = table?table.name:''
                const total_sold_price = this.toNumber(element.total_sold_price)
                const index = tables.findIndex(item=>item.key==table.key)
                let include = true
                if (search && table_name) {
                    let name_index = this.MBS.actions.TEXT_UP0(table_name).indexOf(this.MBS.actions.TEXT_UP0(search))
                    if (name_index == -1) {
                        include = false
                    } 
                }

                if (include) {
                    if (index==-1) {
                        let table_orders = {
                            ...table,
                            total_sold_price:total_sold_price,
                            orders_size:1,
                            orders:[]
                        }
                        table_orders.orders.push(element)
                        tables.push(table_orders)
                    } else {
                        tables[index].orders.push(element)
                        tables[index].orders_size++
                        tables[index].total_sold_price = tables[index].total_sold_price+total_sold_price  
                    } 
                }
            }); 
            return tables
            
        }, 
        WaiterItemOrders(){  
            const orders = this.AllItemOrdersByWaiter
            const search = this.search
            if(!orders){return null}
            let tables = []
            orders.forEach(element => {
                const waiter = element.waiter?element.waiter:{name:"Unknown",key:"unknown"}
                const waiter_name = waiter?waiter.name:''
                const total_sold_price = this.toNumber(element.total_sold_price)
                const index = tables.findIndex(item=>item.key==waiter.key)
                let include = true
                if (search && waiter_name) {
                    let name_index = this.MBS.actions.TEXT_UP0(waiter_name).indexOf(this.MBS.actions.TEXT_UP0(search))
                    if (name_index == -1) {
                        include = false
                    } 
                }

                if (include) {
                    if (index==-1) {
                        let table_orders = {
                            ...waiter,
                            total_sold_price:total_sold_price,
                            orders_size:1,
                            orders:[]
                        }
                        table_orders.orders.push(element)
                        tables.push(table_orders)
                    } else {
                        tables[index].orders.push(element)
                        tables[index].orders_size++
                        tables[index].total_sold_price = tables[index].total_sold_price+total_sold_price  
                    } 
                }
            }); 
            return tables
            
        }, 

        LiveSelectedAssistantOrder (){  
            const selected = this.SELECTED_ASSISTANT_ORDER
            const orders = this.AssistantItemOrders 
            if(!selected || !orders){
                this.dialog_view_selected_assistant_order = false
                return null
            }
            const live_order = orders.find(order=>order.key == selected.key)
            if (!live_order) {
                this.dialog_view_selected_assistant_order = false
            }
            return live_order
        },
        LiveSelectedCustomerOrder (){  
            const selected = this.SELECTED_CUSTOMER_ORDER
            const orders = this.CustomerItemOrders 
            if(!selected || !orders){
                this.dialog_view_selected_customer_order = false
                return null
            }
            const live_order = orders.find(order=>order.key == selected.key)
            if (!live_order) {
                this.dialog_view_selected_customer_order = false
            }
            return live_order
        },

        LiveSelectedTableOrder(){  
            const selected = this.SELECTED_TABLE_ORDER
            const orders = this.TableItemOrders 
            if(!selected || !orders){
                this.dialog_view_selected_table_order = false
                return null
            }
            const live_order = orders.find(order=>order.key == selected.key)
            if (!live_order) {
                this.dialog_view_selected_table_order = false
            }
            return live_order
        },
        

        LiveSelectedWaiterOrder(){  
            const selected = this.SELECTED_WAITER_ORDER
            const orders = this.WaiterItemOrders 
            if(!selected || !orders){
                this.dialog_view_selected_waiter_order = false
                return null
            }
            const live_order = orders.find(order=>order.key == selected.key)
            if (!live_order) {
                this.dialog_view_selected_waiter_order = false
            }
            return live_order
        },


        Waiters(){
            const options = this.ItemOrderOptionsData
            if(!options){return null}
            const items = options.filter(item=>item.type_key == "waiter")
            return items
        },
        Tables(){
            const options = this.ItemOrderOptionsData
            if(!options){return null}
            const items = options.filter(item=>item.type_key == "table")
            return items
        },

        CurrentTill(){
            const tills = this.TillsData
            const cashup = this.LocalCashup 
            if(!tills || !cashup){return null}
            const filled = tills.find(item=>{
                return item.key == cashup.till_key
            })
            return filled
        },
        CurrentOutlet(){
            const outlets = this.OutletsData
            const till = this.CurrentTill   
            if(!outlets || !till){return null}
            const filled = outlets.find(outlet=>outlet.code==till.outlet_code) 
            return filled
        }, 
        CurrentWaiter(){
            const ud = this.ud
            const uid = ud?.uid
            // const waiter_uid = this.waiter_uid ? this.waiter_uid: uid
            const waiter_uid = this.waiter_uid  


            const waiters = this.Waiters     
            if(!waiters || !waiter_uid){return null}
            const filtered = waiters.find(waiter=>waiter.uid==waiter_uid) 
            let user  = this.MBS.actions.JOIN_USER(this.$store.getters.getCompanyUserByKey(waiter_uid))
            let company_user_group_code = user?.company_user_group_code
            let company_user_group = user?.company_user_group
            let company_user_group_name = company_user_group?.name
            let details = null
            if (user) {
                details = {
                    user:user,
                    company_user_group_code:company_user_group_code,
                    company_user_group:company_user_group,
                    company_user_group_name:company_user_group_name,
                }
                
            }
            if (filtered) {
                details = {
                    ...filtered,
                    ...details 
                }
            }
            return details
        }, 
          
        MyCompanies(){ 
            return this.$store.getters.getMyCompanies
        },
        JoinedMyCompanies(){ 
            const companies = this.MyCompanies 
            if(!companies){return null}
            let joined = this.MBS.actions.JOIN({
                name:DATA.ITEMS.COMPANIES.values,
                items:companies
            })  
            return joined
        }, 
        LocalCashup(){   
            let cashups = this.LocalCashups
            let local_cashup = cashups?cashups.find(item=>{
                return !item.closed
            }):null  
            return local_cashup
        },
        Assistant(){    
            const cashup = this.LocalCashup
            const assistant_uid = cashup?.assistant_uid
            if(!assistant_uid){return null} 
            let assistant = {assistant_uid}
            const user = this.$store.getters.getUserByUid(assistant_uid) 
            if (user) {
                assistant = {...user,assistant_uid}
            } 
            return assistant
        }, 
        IsRestaurant(){
            const outlet = this.CurrentOutlet
            return outlet?.outlet_type_code == "restaurant"
        },
        HasAssistant(){
            const till = this.CurrentTill
            const tills = this.TillsData
            if(!till || !tills){return false}
            const assistant = tills.find(item=>item.cashier_till_key == till.key)
            return assistant?true:false
        },
        Joined_LocalCashup(){   
            let localCashup = this.LocalCashup
            let joined = this.MBS.actions.JOIN_CASHUP(localCashup)  
            return joined
        },
        DataSource(){
            const Orders = this.AllItemOrdersByWaiter  
            const Items = this.ItemsData  
            const ItemCategories = this.ItemCategories  
            const ItemDepartments = this.ItemDepartments  
                

            const Outlets = this.OutletsData  
            const Tills = this.DayShiftsTills 
            const DayShifts = this.UNCLOSED_DayShifts  
            const Customers = this.CustomersData  
            const CustomerGroups = this.CustomerGroups  
                
            const ReceivableAccounts = this.ReceivableAccounts  
            const SellingModels = this.sellingModels   
            const AllPrinters = this.AllPrinters   
            const PricingTiers = this.AllPricingTiers   
            const Tables = this.Tables   
            const Waiters = this.Waiters   
            let Outlet = this.CurrentOutlet
            let Assistant = this.Assistant
            return {
                Orders:Orders, 
                Items:Items, 
                ItemCategories:ItemCategories, 
                ItemDepartments:ItemDepartments, 
                    

                Outlets:Outlets, 
                Tills:Tills, 
                DayShifts:DayShifts, 
                Customers:Customers,
                CustomerGroups:CustomerGroups,
                    
                ReceivableAccounts:ReceivableAccounts,
                SellingModels:SellingModels,
                AllPrinters:AllPrinters,
                PricingTiers:PricingTiers,
                Tables:Tables,
                Waiters:Waiters,
                Outlet:Outlet,
                OrderOptions:this.ActionAndProcessingOrderOption,
                Assistant:Assistant,
                is_assistant:Assistant?true:false,
                is_restaurant:Outlet?.outlet_type_code == "restaurant"?true:false
            }
        }, 
        TableActions(){ 
            const IsRestaurant = this.IsRestaurant
            const HasAssistant = this.HasAssistant
            let actions = [] 

            actions.push(...[ 
                {type:"action",btn:true,icon:"mdi-refresh",action:"refresh", text:"Refresh Orders"},    
            ]) 
            if (this.dialog_view_assistant_orders) {
                actions.push({type:"action",btn:true,icon:'mdi-cart',action:"order_list", text:"Order List"})
                actions.push({type:"action",btn:true,icon:'mdi-cart',action:"customer_orders", text:"Customer Orders"})
                if (IsRestaurant) {
                    actions.push({type:"action",btn:true,icon:'mdi-table-chair',action:"table_orders", text:"Table Orders"})
                    actions.push( {type:"action",btn:true,icon:'mdi-face-agent',action:"waiter_orders", text:"Waiter Orders"}) 
                }
            }else if (this.dialog_view_customers_orders) {
                actions.push({type:"action",btn:true,icon:'mdi-cart',action:"order_list", text:"Order List"})
                if (HasAssistant) { 
                    actions.push({type:"action",btn:true,icon:'mdi-cash-register',action:"assistant_orders", text:"Assistant Orders"})
                }
                if (IsRestaurant) {
                    actions.push({type:"action",btn:true,icon:'mdi-table-chair',action:"table_orders", text:"Table Orders"})
                    actions.push( {type:"action",btn:true,icon:'mdi-face-agent',action:"waiter_orders", text:"Waiter Orders"}) 
                }
            }else if (this.dialog_view_waiter_orders) {
                actions.push({type:"action",btn:true,icon:'mdi-cart',action:"order_list", text:"Order List"})
                if (HasAssistant) { 
                    actions.push({type:"action",btn:true,icon:'mdi-cash-register',action:"assistant_orders", text:"Assistant Orders"})
                }
                actions.push({type:"action",btn:true,icon:'mdi-cart',action:"customer_orders", text:"Customer Orders"})
                if (IsRestaurant) {
                    actions.push({type:"action",btn:true,icon:'mdi-table-chair',action:"table_orders", text:"Table Orders"}) 
                }
            }else if (this.dialog_view_table_orders) {
                actions.push({type:"action",btn:true,icon:'mdi-cart',action:"order_list", text:"Order List"})
                if (HasAssistant) { 
                    actions.push({type:"action",btn:true,icon:'mdi-cash-register',action:"assistant_orders", text:"Assistant Orders"})
                }
                actions.push({type:"action",btn:true,icon:'mdi-cart',action:"customer_orders", text:"Customer Orders"})
                if (IsRestaurant) { 
                    actions.push( {type:"action",btn:true,icon:'mdi-face-agent',action:"waiter_orders", text:"Waiter Orders"}) 
                }
            }else{
                actions.push({type:"action",btn:true,icon:'mdi-cart',action:"customer_orders", text:"Customer Orders"})
                if (HasAssistant) {
                    actions.push({type:"action",btn:true,icon:'mdi-cash-register',action:"assistant_orders", text:"Assistant Orders"}) 
                }
                if (IsRestaurant) {
                    actions.push({type:"action",btn:true,icon:'mdi-table-chair',action:"table_orders", text:"Table Orders"})
                    actions.push( {type:"action",btn:true,icon:'mdi-face-agent',action:"waiter_orders", text:"Waiter Orders"}) 
                }
            }  
            
            actions.push(...[ 
                {type:"action",btn:true,icon:'mdi-delete',action:"delete_all_orders", text:"Delete All Orders"},   
                {type:"action",btn:true,icon:'mdi-microsoft-excel',action:"export_order_to_excel", text:"Export Order to Orders"},   
                {type:"action",btn:true,icon:'mdi-exit-to-app',action:"exit", text:"Exit / Close"},
            ])
            return  actions
        },
        TabOderOptions(){  
            const all = this.ActionAndProcessingOrderOption
            if(!all){return null}
            let tab =null

            let order_options = [
                {
                    name:"All",
                    key:'all',
                    code:'all',
                    // indicator:{
                    //     value:this.MBS.actions.SIZE(this.AllItemOrdersByWaiter),
                    //     color:'primary'
                    // }
                }
            ]
            order_options.push(...all)
            const findOrder = option=>{
                
                if(!this.AllItemOrdersByWaiter){return null}
                if(option.key=='all'){return this.AllItemOrdersByWaiter} 
                return this.AllItemOrdersByWaiter.filter(item=>{
                    const departs = item.joined_items_departments 
                    if (this.MBS.actions.SIZE(departs)>0) { 
                        const depart = departs?departs.find(depart=>depart.key == option.item_department_key ):null 
                        return depart || item.order_option_key == option.key 
                    }
                    return  item.order_option_key == option.key
                })
            }
            order_options.forEach(element => {
                if(!tab){tab=[]}
                tab.push({
                    ...element,
                    indicator:{
                        value:this.MBS.actions.SIZE(findOrder(element)),
                        color:'primary'
                    }
                })
            });
            return tab
        },
        TabOderItems(){  
            const all = this.ActionAndProcessingOrderOption
            const AssistantItemOrders = this.AssistantItemOrders
            const CustomerItemOrders = this.CustomerItemOrders
            const WaiterItemOrders = this.WaiterItemOrders
            const TableItemOrders = this.TableItemOrders
            const IsRestaurant = this.IsRestaurant
            const HasAssistant = this.HasAssistant
            if(!all){return null}
            let tab =null

            let order_options = []

            if (HasAssistant) {
                order_options.push({
                    name:"Assistants",
                    key:'assistants',
                    code:'assistants',
                })
            }
            order_options.push({
               name:"Customers",
               key:'customers',
               code:'customers',
           })
           if (IsRestaurant) {
                order_options.push({
                    name:"Waiters",
                    key:'waiters',
                    code:'waiters',
                })
                order_options.push({
                    name:"Tables",
                    key:'tables',
                    code:'tables',
                })
           }

            let order_data = {
                assistants:AssistantItemOrders,
                customers:CustomerItemOrders,
                waiters:WaiterItemOrders,
                tables:TableItemOrders,
            }
            
            order_options.forEach(element => {
                if(!tab){tab=[]}
                tab.push({
                    ...element,
                    indicator:{
                        value:this.MBS.actions.SIZE(order_data[element.key]),
                        color:'primary'
                    }
                })
            });
            return tab
        }, 
        PageItemOrders(){  
            const tab = this.currentOrderOptionTab
            const orders = this.AllItemOrdersByWaiter
            const department = this.ItemDepartmentsData
            const order_options = [
                {
                    name:"All",
                    key:'all',
                    code:'all',
                    // indicator:{
                    //     value:this.MBS.actions.SIZE(this.AllItemOrdersByWaiter),
                    //     color:'primary'
                    // }
                }
            ]  
            if ( this.ActionAndProcessingOrderOption) {
                order_options.push(... this.ActionAndProcessingOrderOption) 
            } 
            let current_option = null

             
            if(order_options){
                current_option = order_options[tab]
            }    
            
            if(!orders){return null}
            if(!current_option){return orders}
            if(current_option?.key=="all"){return orders}
            const filtered = orders.filter(item=>{
                let departs = item.joined_items_departments
                if (this.MBS.actions.SIZE(departs)>0) { 
                    let depart = departs?departs.find(depart=>depart.key == current_option.item_department_key):null 
                    return depart || item.order_option_key == current_option.key 
                }
                return item.order_option_key == current_option.key 
            }) 
            return filtered
        }, 
        MergeItems(){   
            return  (items,merge_doc_type)=>{
                let merged = this.MBS.actions.MERGE_DUPLICATED_ITEMS({
                    items:items,
                    merge_doc_type:merge_doc_type
                })  
                return merged
            }
        }, 
         
        liveTime(){
            let time = this.MBS.date.live 
            return time
        },  
        online(){   
            return this.$store.getters.getOnlineStatus
        },
        onlineOrder(){   
            let online = this.online
            let downloaded = this.DOWNLOADED_ORDERS 
            return online && downloaded
        },

        Printers(){
                let devices = this.DevicesDataItems  
                let printers = []
                if (devices) {
                    printers = devices.filter(item=>{
                        return this.MBS.actions.TEXT_UP(item.device_type)
                         == this.MBS.actions.TEXT_UP(DATA.ITEMS.PRINTERS.value)
                    })  
                }
                return [
                    {
                        code:0,
                        name:"Other Installed Printer",
                        isDefaultPrinter:true,
                        priority:1
                    },
                    ...printers
                ]
        },
        AllPrinters(){
            let printers = this.Printers  
            let connected_printers = this.ConnectedPrinters  
            if(!printers && connected_printers){return null}
            let new_printers = []
            let find_printer=(name,printers)=>{
                if(!printers){return null}
                return printers.find(printer=>printer.name==name)
            }
            
            
            if(connected_printers){
                connected_printers.forEach(printer => {
                    let company_printer = find_printer(printer.name,printers)
                    if (company_printer) {
                        new_printers.push({
                            ...company_printer,
                            ...printer,
                            is_installed:true,
                            is_company_printers:true,
                            is_active:true,
                            priority:printer.isDefaultPrinter?1:2
                        })
                    }else{
                        new_printers.push({ 
                            ...printer,
                            is_installed:true, 
                            priority:printer.isDefaultPrinter?1:4
                        })
                    }
                });
            }

            if(printers){
                printers.forEach(printer => {
                    if (!find_printer(printer.name,connected_printers)) {
                        new_printers.push({
                            ...printer,
                            is_company_printers:true,
                            priority:printer.isDefaultPrinter?1:3
                        })
                    }
                });
            } 
            let list = [...new_printers]
            list.sort(this.MBS.actions.dynamicSort("priority",true))   
            return list  
        },
        ReceivableAccounts(){ 
            return this.$store.getters.getReceivables
        },  
         
    },
    methods:{  
        TABLE_ACTION(action,item){
            try { 
                let route = this.route
                let fullPath = route?route.fullPath:null 
                if (action == "refresh") { 
                    this.REFRESH_ORDERS()
                }else if (action == "order_list") { 
                    this.CHANGE_ORDER_LIST_VIEW('list')
                }else if (action == "assistant_orders") { 
                    this.CHANGE_ORDER_LIST_VIEW('assistant')
                }else if (action == "customer_orders") { 
                    this.CHANGE_ORDER_LIST_VIEW('customer')
                }else if (action == "table_orders") { 
                    this.CHANGE_ORDER_LIST_VIEW('table')
                }else if (action == "waiter_orders") { 
                    this.CHANGE_ORDER_LIST_VIEW('waiter')
                }else if (action == "delete_all_orders") { 
                    this.CONFIRM_DELETE_ALL_ITEM_ORDER()
                }else if (action == "exit") { 
                    this.EXIT_DIALOG()
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'TABLE_ACTION',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        INITIATE_DEVICE(till){
            try { 
                this.current_device=null
                this.show_device_selector = false
                setTimeout(() => {
                    this.show_device_selector = true
                    if (till && this.Assistant) {
                        this.current_device=till.key
                    } else {
                        this.current_device="all"
                    }   
                }, 10);
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'INITIATE_DEVICE',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        async CLEAR_ITEM_ORDERS(LocalCashup){
            try {   
                let day_shift_key = this.LocalCashup?.day_shift_key
                let cashup_key = this.LocalCashup?.key
                if (!cashup_key) {return}
                if(this.clearing_item_orders){
                    setTimeout(() => {
                        this.CLEAR_ITEM_ORDERS(LocalCashup) 
                    }, 10000);
                }else{
                    this.clearing_item_orders = true
                    let res_local_sales = await DB.getItems({
                        name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values
                    })
                    let local_item_orders = res_local_sales?.data
                    let delete_orders = local_item_orders?local_item_orders.filter(order=>{
                        return order.uploaded && order.cashup_key!=cashup_key
                    }):null
                    if (this.MBS.actions.SIZE(delete_orders)) { 
                        let res =  await DB.deleteItems({ 
                            name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values, 
                            update_locals:true, 
                            items:delete_orders
                        }) 
                    } 
                    this.clearing_item_orders = false
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CLEAR_SELECTED_ITEMS',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        RESET_ALL_DATA(){ 
            try { 
                Object.assign(this.$data, this.$options.data())  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'RESET_ALL_DATA',
                    page:PAGE_NAME, 
                }) 
            }
        },
        SELECT_ORDER_OPTION(order_option){ 
            try { 
                let outlet = this.CurrentOutlet
                let assistant = this.Assistant
                let waiter = this.CurrentWaiter
                let waiter_key =waiter?.key
                let order_option_key =order_option?.key
                if (waiter_key) {
                    this.input.waiter_key = waiter_key
                } 
                if (order_option_key) {
                    this.input.order_option_key = order_option_key
                }

                this.SELECTED_ORDER_OPTION = order_option
                if (order_option_key == "update_order") {
                    this.dialog_update_order_option = true
                } else if (order_option_key) {
                    this.dialog_new_order_option= true 
                } else {
                    this.$emit("on_select_order_option",order_option) 
                }
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'RESET_ALL_DATA',
                    page:PAGE_NAME, 
                }) 
            }
        },
        SEND_AND_PRINT_NEW_ORDER(order_option){ 
            try { 
                this.menu_send_and_print_option = false
                this.PUT_AND_PRINT = order_option 
                this.$refs.ref_btn_send_and_print.$el.click();
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'SEND_AND_PRINT_NEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },
        CREATE_NEW_ORDER(input,order_option){ 
            try { 
                let data = {
                    ...input,
                    print:this.PUT_AND_PRINT?true:false, 
                    print_option:this.PUT_AND_PRINT
                } 
                this.inputLoading = true  
                this.$emit("send_kitchen_order_option",data,order_option) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CREATE_NEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },
        UPDATE_EXISTING_ORDER(input,order_option,order_printing_option){ 
            try { 
                let data = {
                    ...input,
                    print:this.PUT_AND_PRINT, 
                    order_printing_option:order_printing_option
                }  
                this.dialog_update_order_option= false
                this.$emit("CONFIRM_UPDATE_EXISTING_ORDER",data,order_option) 
                this.ORDER_PRINT_OPTIONS = null
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CREATE_NEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },
        CHANGED_ITEM_ORDER_TAB(value){ 
            try {
                this.dialog_view_assistant_orders = false
                this.dialog_view_customers_orders = false
                this.dialog_view_waiter_orders = false
                this.dialog_view_table_orders = false
                const tabs = this.TabOderItems
                const tab = tabs?tabs[value]:null

                if (tab?.key == "assistants") {
                    this.dialog_view_assistant_orders = true
                }else if (tab?.key == "customers") {
                    this.dialog_view_customers_orders = true
                }else if (tab?.key == "waiters") {
                    this.dialog_view_waiter_orders = true
                }else if (tab?.key == "tables") {
                    this.dialog_view_table_orders = true
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHANGED_ITEM_ORDER_TAB',
                    page:PAGE_NAME, 
                }) 
            }
        },
        CHANGE_ORDER_LIST_VIEW(type){
            try {      
                this.dialog_view_orders_list = false
                this.dialog_view_assistant_orders = false
                this.dialog_view_customers_orders = false
                this.dialog_view_waiter_orders = false
                this.dialog_view_table_orders = false
                const taps = this.TabOderItems

                setTimeout(() => {
                    if (type == 'list') {
                        this.dialog_view_orders_list = true 
                    }else if (type == 'table') {
                        this.dialog_view_table_orders = true
                        const index  = taps?.findIndex(tab=>tab.key =="tables")
                        this.currentItemOrderTab = index
                    }else if (type == 'waiter') {
                        this.dialog_view_waiter_orders = true
                        const index  = taps?.findIndex(tab=>tab.key =="waiters")
                        this.currentItemOrderTab = index
                    }else if (type == 'assistant') {
                        this.dialog_view_assistant_orders = true
                        const index  = taps?.findIndex(tab=>tab.key =="assistants")
                        this.currentItemOrderTab = index
                    }else{
                        this.dialog_view_customers_orders = true
                        const index  = taps?.findIndex(tab=>tab.key =="customers")
                        this.currentItemOrderTab = index
                    } 
                }, 500);
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHANGE_ORDER_LIST_VIEW',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        ON_UPDATE_ORDER_OPTION(payload){ 
            try {
                this.menu_update_order_option = false
                const item_order = this.item_order
                this.$emit("ON_UPDATE_ORDER_OPTION",{
                    ...payload,
                    item_order:item_order
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CREATE_NEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },
        ON_EDIT_ORDER_OPTION(payload){ 
            try {
                this.menu_edit_order_option = false
                const item_order = this.item_order
                this.$emit("ON_EDIT_ORDER_OPTION",{
                    ...payload,
                    item_order:item_order
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CREATE_NEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },
        ON_UPDATE_EXISTING_ORDER_OPTION(option){ 
            try {
                this.menu_update_existing_order_option = false
                this.ORDER_PRINT_OPTIONS = option 
                this.$refs.ref_btn_update_print.$el.click();
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CREATE_NEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },
        
        ON_SUBMITTED_ITEM_ORDERS(){ 
            try {  
                this.inputLoading = false
                this.dialog_new_order_option = false
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_SUBMITTED_ITEM_ORDERS',
                    page:PAGE_NAME, 
                }) 
            }
        },
        ON_VIEW_ORDERS(type){
            try {     
                const taps = this.TabOderItems
                if (type == "view_orders_list") {
                    this.dialog_view_orders_list = !this.dialog_view_orders_list 
                }else if (type == "view_assistant_till_orders") {
                    this.dialog_view_assistant_orders = !this.dialog_view_assistant_orders
                    const index  = taps?.findIndex(tab=>tab.key =="assistants")
                    this.currentItemOrderTab = index
                }else if (type == "view_customer_orders") {
                    this.dialog_view_customers_orders = !this.dialog_view_customers_orders 
                    const index  = taps?.findIndex(tab=>tab.key =="customers")
                    this.currentItemOrderTab = index
                }else if (type == "view_table_orders") {
                    this.dialog_view_table_orders = !this.dialog_view_table_orders 
                    const index  = taps?.findIndex(tab=>tab.key =="tables")
                    this.currentItemOrderTab = index
                }else if (type == "view_waiter_orders") {
                    this.dialog_view_waiter_orders = !this.dialog_view_waiter_orders 
                    const index  = taps?.findIndex(tab=>tab.key =="waiters")
                    this.currentItemOrderTab = index
                }else {
                    
                }
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_VIEW_ORDERS',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        ON_VIEW_ORDER(order,index){
            try {   
                this.SELECTED_ORDER = {...order,expand_items:true,expand_details:false}
                this.dialog_view_order = !this.dialog_view_order
                if (order?.new_order) { 
                    const updating_order = this.MBS.actions.TO_UPLOAD_ORDER(order) 
                    DB.updateItems({
                        name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                        data:updating_order,
                        update_locals:true
                    })
                }
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_VIEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        ON_VIEW_BILL({bill}){
            try {  
                const orders = this.AllItemOrders
                const order = orders?.find(order=>order.key == bill?.key)
                if (order) {
                    this.ON_VIEW_ORDER(order) 
                }else{
                    console.log('no order+++++++++++');
                }
                console.log(bill,'b...........b');
                console.log(order,'order...........b');
                console.log(orders,'orders...........ss');
                 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_VIEW_BILL',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        ON_VIEW_ASSISTANT_ORDER(order,index){
            try {     
                this.SELECTED_ASSISTANT_ORDER = order
                this.dialog_view_selected_assistant_order = !this.dialog_view_selected_assistant_order
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_VIEW_ASSISTANT_ORDER',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        ON_VIEW_CUSTOMER_ORDER(order,index){
            try {     
                this.SELECTED_CUSTOMER_ORDER = order
                this.dialog_view_selected_customer_order = !this.dialog_view_selected_customer_order
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_VIEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        ON_VIEW_TABLE_ORDER(order,index){
            try {     
                this.SELECTED_TABLE_ORDER = order
                this.dialog_view_selected_table_order = !this.dialog_view_selected_table_order
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_VIEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        ON_VIEW_WAITER_ORDER(order,index){
            try {     
                this.SELECTED_WAITER_ORDER = order
                this.dialog_view_selected_waiter_order = !this.dialog_view_selected_waiter_order
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_VIEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        REFRESH_ORDERS(){
            try {      
                this.$emit("REFRESH_ORDERS") 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'REFRESH_ORDERS',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        CREATE_ORDER(){
            this.$emit('create_order')
        },
        CREATE_QUOTATION(){
            this.dialog_create_quotation = true
        },
        SUBMIT_QUOTATION(input,order){ 
            this.dialog_create_quotation = false
            this.$emit('create_quotation',{input,order})
            // console.log(input,'input..........');
            // console.log(order,'order..........');
            
        },
        

        EXIT_DIALOG(){
            try { 
                this.dialog_print_order = false 

                this.dialog_new_order_option = false 
                this.dialog_update_order_option = false 
                this.dialog_view_orders_list = false 
                this.dialog_view_assistant_orders = false 
                this.dialog_view_customers_orders = false 
                this.dialog_view_options_orders = false 
                this.dialog_view_table_orders = false 
                this.dialog_view_selected_assistant_order = false 
                this.dialog_view_selected_customer_order = false 
                this.dialog_view_selected_table_order = false 
                this.dialog_view_waiter_orders = false 
                this.dialog_view_selected_waiter_order = false 
                this.dialog_view_selected_option_order = false  
                this.dialog_view_order = false 

            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'EXIT_DIALOG',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        EXIT_ORDERS(){
            try {  
                this.dialog_view_waiter_orders = false 
                this.dialog_update_order_option = false 
                this.dialog_view_orders_list = false 
                this.dialog_view_assistant_orders = false 
                this.dialog_view_customers_orders = false 
                this.dialog_view_options_orders = false 
                this.dialog_view_table_orders = false 
                this.dialog_view_selected_assistant_order = false 
                this.dialog_view_selected_customer_order = false 
                this.dialog_view_selected_table_order = false 
                this.dialog_view_waiter_orders = false 
                this.dialog_view_selected_waiter_order = false 
                this.dialog_view_selected_option_order = false  
                this.dialog_view_order = false 

            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'EXIT_DIALOG',
                    page:PAGE_NAME, 
                }) 
            } 
        },

        
        HIGHLIGHT_ORDER_ITEMS(order){ 
            try {  
                this.EXIT_DIALOG()
                this.$emit("HIGHLIGHT_ORDER_ITEMS",order) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'HIGHLIGHT_ORDER_ITEMS',
                    page:PAGE_NAME, 
                }) 
            }
        },
        PRINT_ORDER_OPTION_DOCKET(order,version){ 
            try {  
                this.$emit("PRINT_ORDER_OPTION_DOCKET",{
                    order:order,
                    docket_version:version,
                    merge_doc_type:"docket",
                    doc_type:"docket",
                    doc_print_title:"DOCKET",
                    order_type:"sale_order",
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'PRINT_ORDER_OPTION_DOCKET',
                    page:PAGE_NAME, 
                }) 
            }
        },
        PRINT_ORDER_OPTION(order,order_type='bill_sale_order'){ 
            try {   
                this.$emit("PRINT_ORDER_OPTION",{
                    merge_doc_type:"bill",
                    doc_type:"bill",
                    doc_print_title:"CUSTOMER BILL",
                    order:order,
                    orders:order?.orders,
                    order_type:order_type 
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'PRINT_ORDER_OPTION',
                    page:PAGE_NAME, 
                }) 
            }
        },
        PAY_ITEM_ORDER(order){ 
            try {  
                this.$emit("PAY_ITEM_ORDER",order) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'PAY_ITEM_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },
        PAY_MULTIPLE_ITEM_ORDER(orders){ 
            try {   
                this.$emit("PAY_MULTIPLE_ITEM_ORDER",orders) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'PAY_MULTIPLE_ITEM_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },
        

        //--------------[DELETE]------------
        CONFIRM_DELETE_ITEM_ORDER(payload){ 
            try {   
                let order = payload?.order
                let deleting_reason = payload?.deleting_reason
                let notify = payload?.notify

                let settings = this.CompanySettings
                let require_void_to_delete_order = settings?.require_void_to_delete_order
                let mbs_text = this.MBS.text.item_action("Order","Delete")
                let icon = "mdi-delete-empty"
                let title = ""+mbs_text.title
                let text = mbs_text.text
                let dialog =  this.MBS.actions.dialog
                if (require_void_to_delete_order) {
                    icon = "mdi-shield-lock-outline"
                    title = "VOID REQUIRED TO DELETE ORDER"
                    text = "Deleting order(s) require admin permission. Enter your admin phone and password to authorize this action."
                    dialog = this.MBS.actions.dialogConfirmPassword
                }
                dialog({
                    show:true,
                    fixed:true,
                    icon:icon,
                    title:title,
                    text:text,
                    btnYes:mbs_text.btnYes,  
                    btnNo:mbs_text.btnNo,  
                    action:{
                        code:PAGE_NAME+"=DELETE-ITEM-ORDER",
                        data:payload, 
                        phone_number_start:true,
                        all_users:require_void_to_delete_order,
                        get_access:{
                            permission_type:"action", 
                            permission_to:"delete", 
                            action_name:DATA.ITEMS.SALE_ORDERS.names
                        }
                    }
                })  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_DELETE_ITEM_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        }, 
        CONFIRM_DELETE_MULTIPLE_ITEM_ORDERS(payload){ 
            try {   
                let deleting_reason = payload?.deleting_reason
                let notify = payload?.notify
                let item_order = payload?.item_order
                let name = item_order?.name
                let type_key = item_order?.type_key
                let orders = item_order?.orders
                let orders_size = this.MBS.actions.SIZE(orders)
 

                let settings = this.CompanySettings
                let require_void_to_delete_order = settings?.require_void_to_delete_order
                let mbs_text = this.MBS.text.item_action(name+" Order(s)","Delete")
                let icon = "mdi-delete-empty"
                let title = ""+mbs_text.title
                let text = mbs_text.text+" "+orders_size+type_key+" order(s) will be deleted."
                let dialog =  this.MBS.actions.dialog
                if (require_void_to_delete_order) {
                    icon = "mdi-shield-lock-outline"
                    title = "VOID REQUIRED TO DELETE ORDER"
                    text = "Deleting order(s) require admin permission. Enter your admin phone and password to authorize this action."
                    dialog = this.MBS.actions.dialogConfirmPassword
                }
                dialog({
                    show:true,
                    fixed:true,
                    icon:icon,
                    title:title,
                    text:text,
                    btnYes:mbs_text.btnYes,  
                    btnNo:mbs_text.btnNo,  
                    action:{
                        code:PAGE_NAME+"=DELETE-MULTIPLE-ITEM-ORDERS",
                        data:payload, 
                        all_users:require_void_to_delete_order,
                        get_access:{
                            permission_type:"action", 
                            permission_to:"delete", 
                            action_name:DATA.ITEMS.SALE_ORDERS.names
                        }
                    }
                })  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_DELETE_MULTIPLE_ITEM_ORDERS',
                    page:PAGE_NAME, 
                }) 
            }
        }, 
        CONFIRM_DELETE_ALL_ITEM_ORDER(payload){ 
            try {   
                const orders = this.AllItemOrders
                const orders_size = this.MBS.actions.SIZE(orders)
                let settings = this.CompanySettings
                let require_void_to_delete_order = settings?.require_void_to_delete_order
                let mbs_text = this.MBS.text.item_action("All Order(s)","Delete")
                let icon = "mdi-delete-empty"
                let title = ""+mbs_text.title
                let text = mbs_text.text+" "+orders_size+" order(s) will be deleted."
                let dialog =  this.MBS.actions.dialog
                if (require_void_to_delete_order) {
                    icon = "mdi-shield-lock-outline"
                    title = "VOID REQUIRED TO DELETE ORDER"
                    text = "Deleting order(s) require admin permission. Enter your admin phone and password to authorize this action."
                    dialog = this.MBS.actions.dialogConfirmPassword
                }
                dialog({
                    show:true,
                    fixed:true,
                    icon:icon,
                    title:title,
                    text:text,
                    btnYes:mbs_text.btnYes,  
                    btnNo:mbs_text.btnNo,  
                    action:{
                        code:PAGE_NAME+"=DELETE-ALL-ITEM-ORDERS",
                        data:payload, 
                        all_users:require_void_to_delete_order,
                        get_access:{
                            permission_type:"action", 
                            permission_to:"delete", 
                            action_name:DATA.ITEMS.SALE_ORDERS.names
                        }
                    }
                })  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_DELETE_ALL_ITEM_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        }, 
        DELETE_ITEM_ORDER(payload){
            let response = {
                from:'DELETE_ITEM_ORDER',
                page:PAGE_NAME, 
                payload:payload
            }
            try {   
                let order = payload?.order
                let item_order_key = order?.key
                let day_shift_key = order?.day_shift_key
                let company_key = order?.company_key
                let created_uid = order?.created_uid
                let deleting_reason = payload?.deleting_reason
                let notify = payload?.notify
                let order_version = this.toNumber(order?.order_version ) 

                const updating_order = {
                    key:item_order_key,
                    day_shift_key:day_shift_key,
                    company_key:company_key,
                    created_uid:created_uid,
                    deleted:true,
                    deleting_reason:deleting_reason,
                    uploaded:false,
                    order_version:order_version+1, 
                }
                this.LAN_ORDER({order:updating_order,update:true})
                .then(res=>{
                    const {data,response}=res
                    const success = data?.success 
                    return DB.updateItems({
                        name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                        data:updating_order,
                        update_locals:true
                    }) 
                })
                .then(res=>{  
                    this.$emit("on_deleted_item_order",res)  
                    this.dialog_view_order = false
                    if (notify) { 
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ORDERS.name,"Deleting")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }
                }).catch(error=>{
                    this.MBS.actions.error({...response,error:error,no_upload:true }) 
                    if (notify) { 
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ORDERS.name,"Deleting")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }
                }) 
            } catch (error) {
                this.MBS.actions.error({...response,error:error }) 
            } 
        },
        async DELETE_MULTIPLE_ITEM_ORDERS(payload){
            let response = {
                from:'DELETE_MULTIPLE_ITEM_ORDERS',
                page:PAGE_NAME, 
                payload:payload
            }
            try {   
                let deleting_reason = payload?.deleting_reason
                let notify = payload?.notify
                let item_order = payload?.item_order
                let orders = item_order?.orders
                if(!orders){return null}
                let updating_orders = []
                orders.forEach(order => {
                    let item_order_key = order?.key
                    let day_shift_key = order?.day_shift_key
                    let company_key = order?.company_key
                    let created_uid = order?.created_uid 
                    let order_version = this.toNumber(order?.order_version )
                    updating_orders.push({
                        key:item_order_key,
                        day_shift_key:day_shift_key,
                        company_key:company_key,
                        created_uid:created_uid,
                        deleted:true,
                        deleting_reason:deleting_reason,
                        uploaded:false,
                        order_version:order_version+1,
                        update_locals:true
                    }) 
                }); 

                 
                this.LAN_ORDER({order:updating_orders,update:true,multiple:true})
                .then(res=>{
                    const {data,response}=res 
                    return DB.updateItems({
                        name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                        data:updating_orders,
                        update_locals:true
                    }) 
                }).then(res=>{  
                    this.$emit("on_deleted_item_order",res)  
                    this.dialog_view_order = false
                    if (notify) { 
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ORDERS.name,"Deleting")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }
                }).catch(error=>{
                    this.MBS.actions.error({...response,error:error,no_upload:true }) 
                    if (notify) { 
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ORDERS.name,"Deleting")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }
                }) 
            } catch (error) {
                this.MBS.actions.error({...response,error:error }) 
            } 
        }, 
        async DELETE_ALL_ITEM_ORDER(payload){
            let response = {
                from:'DELETE_ALL_ITEM_ORDER',
                page:PAGE_NAME, 
                payload:payload
            }
            try {   
                let deleting_reason = payload?.deleting_reason
                let notify = payload?.notify
                const orders = this.AllItemOrders
                if(!orders){return null}
                let updating_orders = []
                orders.forEach(order => {
                    let item_order_key = order?.key
                    let day_shift_key = order?.day_shift_key
                    let company_key = order?.company_key
                    let created_uid = order?.created_uid 
                    let order_version = this.toNumber(order?.order_version )
                    updating_orders.push({
                        key:item_order_key,
                        day_shift_key:day_shift_key,
                        company_key:company_key,
                        created_uid:created_uid,
                        deleted:true,
                        deleting_reason:deleting_reason,
                        uploaded:false,
                        order_version:order_version+1,
                        update_locals:true
                    }) 
                }); 

                this.LAN_ORDER({order:updating_orders,update:true,multiple:true})
                .then(res=>{
                    const {data,response}=res 
                    return DB.updateItems({
                        name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                        data:updating_orders,
                        update_locals:true
                    }) 
                })
                .then(res=>{  
                    this.$emit("on_deleted_item_order",res)  
                    this.dialog_view_order = false
                    if (notify) { 
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ORDERS.name,"Deleting")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }
                }).catch(error=>{
                    this.MBS.actions.error({...response,error:error,no_upload:true }) 
                    if (notify) { 
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ORDERS.name,"Deleting")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }
                }) 
            } catch (error) {
                this.MBS.actions.error({...response,error:error }) 
            } 
        }, 
        LAN_ORDER(payload){
            let response={
                from:'LAN_ORDER',
                page:PAGE_NAME,  
                payload
            }
            return new Promise((resolve,reject)=>{
                try{
                    const {order,notify,update,multiple} = payload
                    const online = this.online_lan2
                    const assistant = this.Assistant
                    const order_key = update?""+order?.key:"new_key"
                    const action = update?"lan_update":"lan_set" 
                    const request_order = multiple?{items:order}:{...order,key:order_key}
 
                    if (online || assistant) {
                        this.$store.dispatch(action,{
                            action:"ITEM_ORDERS",
                            name:"ITEM_ORDERS",
                            data:request_order,
                            lan:2,
                        }).then(res=>{
                            resolve(res) 
                        }).catch(error=>{
                            reject(error)
                        }) 
                        return
                    }else{ 
                        resolve({
                            ...payload,online,assistant,
                            data:order,
                            success:false,
                            error:"not connected"
                        })
                    } 
                }catch(error){
                    this.MBS.actions.error({
                        ...response,
                        error:error, 
                    }) 
                }
            })

        },

        ON_UPDATE_BILL(order){
            try {  
                let mbs_text = this.MBS.text.item_action("Bill.","Update")
                this.MBS.actions.dialogConfirmPassword({
                    show:true,
                    fixed:true,
                    icon:"mdi-shield-lock-outline",
                    title:mbs_text.title,
                    text:mbs_text.text+" Enter your password to confirm.",
                    action:{
                        code:PAGE_NAME+"=UPDATE-BILL", 
                        data:order,
                        phone_number_start:true,
                        all_users:true, 
                        get_access:{
                            permission_type:"option", 
                            action_name:"Update Sale Order"  
                        }
                    }
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_UPDATE_BILL',
                    page:PAGE_NAME, 
                })
            }  
        },   
        ON_REPRINT_BILL(order,type){
            try {  
                let mbs_text = this.MBS.text.item_action("Bill","Reprint")
                this.MBS.actions.dialogConfirmPassword({
                    show:true,
                    fixed:true,
                    icon:"mdi-shield-lock-outline",
                    title:mbs_text.title,
                    text:mbs_text.text+" Enter your password to confirm.",
                    action:{
                        code:PAGE_NAME+"=REPRINT-BILL", 
                        data:{order,type},
                        phone_number_start:true,
                        all_users:true, 
                        get_access:{
                            permission_type:"option", 
                            action_name:"Reprint Bill"  
                        }
                    }
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_REPRINT_BILL',
                    page:PAGE_NAME, 
                })
            }  
        }, 
        ON_REPRINT_DOCKET(order,version){
            try {  
                let mbs_text = this.MBS.text.item_action("Docket.","Reprint")
                this.MBS.actions.dialogConfirmPassword({
                    show:true,
                    fixed:true,
                    icon:"mdi-shield-lock-outline",
                    title:mbs_text.title,
                    text:mbs_text.text+" Enter your password to confirm.",
                    action:{
                        code:PAGE_NAME+"=REPRINT-DOCKET", 
                        data:{order,version},
                        phone_number_start:true,
                        all_users:true, 
                        get_access:{
                            permission_type:"option", 
                            action_name:"Reprint Docket"  
                        }
                    }
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_REPRINT_DOCKET',
                    page:PAGE_NAME, 
                })
            }  
        },   
         
        //-------------[main]
        async DIALOG_YES(action){ 
            try {
                if (action.code) {
                    if (action.code ===PAGE_NAME+'=CONFIRM-SELECT-COMPANY') {
                        if (action.YES) {
                            setTimeout(() => { 
                                this.input.check = true 
                                this.MBS.actions.dialogInput({
                                    show:true,
                                    fixed:true,
                                    title:"CONFIRM",
                                    text:"Enter your password to confirm switching to "+this.MBS.actions.TEXT_UP(action.data.company_name)+".",
                                    btnYes:"CONFIRM",
                                    field:[
                                        {field:"password",label:'Password',type:'password'}
                                    ],
                                    action:{
                                        ...action,
                                        code:PAGE_NAME+"=SELECT-COMPANY",
                                    }
                                })  
                            }, this.MBS.data.ACTION_REFRESH_TIME); 
                        } else {
                            
                        }  
                    } 
                    if (action.code ===PAGE_NAME+'=SELECT-COMPANY') {
                        if (action.YES) {
                            setTimeout(() => { 
                                let password = action.password
                                password = password?this.MBS.crypt.encrypt(password):null 
                                if (!password) { 
                                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                }else if (password!==this.ud.p_uid) { 
                                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                } else {
                                    this.SELECT_COMPANY(action.data) 
                                }   
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else {
                            
                        }
                    }   

                      
                    if (action.code ===PAGE_NAME+'=DELETE-ITEM-ORDER') {
                        if (action.YES) {
                            setTimeout(() => {  
                                this.DELETE_ITEM_ORDER(action.data)
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                        }     
                    } 
                    if (action.code ===PAGE_NAME+'=DELETE-MULTIPLE-ITEM-ORDERS') {
                        if (action.YES) {
                            setTimeout(() => {  
                                this.DELETE_MULTIPLE_ITEM_ORDERS(action.data)
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                        }     
                    }   
                    if (action.code ===PAGE_NAME+'=DELETE-ALL-ITEM-ORDERS') {
                        if (action.YES) {
                            setTimeout(() => {  
                                this.DELETE_ALL_ITEM_ORDER(action.data)
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                        }     
                    } 
                    if (action.code ===PAGE_NAME+'=UPDATE-BILL') {
                        if (action.YES) {
                            setTimeout(() => {  
                                this.HIGHLIGHT_ORDER_ITEMS(action.data)
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                        }     
                    } 
                    if (action.code ===PAGE_NAME+'=REPRINT-BILL') {
                        if (action.YES) {
                            setTimeout(() => {  
                                this.PRINT_ORDER_OPTION(action?.data?.order,action?.data?.type)
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                        }     
                    }
                    if (action.code ===PAGE_NAME+'=REPRINT-DOCKET') {
                        if (action.YES) {
                            setTimeout(() => {  
                                this.PRINT_ORDER_OPTION_DOCKET(action.data?.order,action.data?.version)
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                        }     
                    } 
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'DIALOG_YES',
                    page:PAGE_NAME, 
                }) 
            }  
        },
    },
    beforeDestroy(){
        this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);      
        this.MBS.events.$off('CONFIRM_DELETE_ITEM_ORDER', this.CONFIRM_DELETE_ITEM_ORDER);   
        this.MBS.events.$off('ON_VIEW_ORDERS', this.ON_VIEW_ORDERS);   
        this.MBS.events.$off('ON_VIEW_BILL', this.ON_VIEW_BILL);   
        this.MBS.events.$off('CONFIRM_DELETE_ALL_ITEM_ORDER', this.CONFIRM_DELETE_ALL_ITEM_ORDER);   
        this.MBS.events.$off('DELETE_ITEM_ORDER', this.DELETE_ITEM_ORDER);   
        this.MBS.events.$off('DELETE_MULTIPLE_ITEM_ORDERS', this.DELETE_MULTIPLE_ITEM_ORDERS);   
        this.MBS.events.$off('DELETE_ALL_ITEM_ORDER', this.DELETE_ALL_ITEM_ORDER);  
    },
    watch:{  
        input(){
             
        }, 
        LocalCashup(value){
            this.CLEAR_ITEM_ORDERS(value)
        },
        Assistant(value){
            // this.INITIATE_DEVICE(value)
        },
        CashupDevices(value){
            this.INITIATE_DEVICE(this.CurrentTill)
        },
        current_device(value){ 
        },
        dialog_view_orders_list(value){ 
            
            if (value) {
                this.INITIATE_DEVICE(this.CurrentTill)
            }
        }
    }
}
</script> 